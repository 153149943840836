// Function to reset the scroll position to the top instantly
export const smoothNavigate = (history, path: string) => {
    // Instantly reset scroll position
  
    // Start the smooth fade-out of the page
    document.body.style.transition = "opacity 0.07s ease-in-out";
    document.body.style.opacity = "0"; 
  
    // Wait for the fade animation to finish before navigating
    setTimeout(() => {
      history.push(path); // Navigate to the new path
      resetScroll();
      // After the transition, smoothly restore the page's visibility
      setTimeout(() => {
        document.body.style.opacity = "1"; // Fade the page back in
      }, 50); // Short delay to avoid any jumps
    }, 70); // Wait for 70ms before performing the navigation
  };
  
  // Function to reset the scroll position instantly without animation
  export const resetScroll = () => {
    document.documentElement.style.scrollBehavior = "auto"; // Disable smooth scroll behavior
    setTimeout(() => {
      window.scrollTo(0, 0); // Instantly scroll to the top of the page
      document.documentElement.style.scrollBehavior = ""; // Restore the default scroll behavior
    }, 0);
  };