import { FC } from 'react';
import { useHistory, useParams} from 'react-router-dom';
import { GuestInformationContainer } from './style';
import { Icon } from 'components/Icon';
import {  CheckInIcon, CheckOutIcon } from 'assets/icons';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import StyledButton from 'components/Button';
import { smoothNavigate } from "components/SmoothNavigate";

import AlertMessage from 'components/AlertMessage';



const debug = true;

interface GuestInformationProps {
    showDone?: boolean; // Optional prop to show/hide done botton - defoult - true
    processAlerts?: boolean; // set false if alrts already processed in parent component - defoult true
}

const GuestInformation: FC<GuestInformationProps> = ({showDone = true, processAlerts = true}) => {



  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);
  
  
  const closeMessage = () => setMessageState({ isVisible: false, text: '' });
  const closeError = () => setErrorState({ isVisible: false, text: '' });


  const history = useHistory()
  const { trips, loading, refetch } = useLoadTrips()
  const { groupId } = useParams<{groupId: string}>()
  const trip = trips[groupId]


  if (debug) {
    console.log ("trip =", trip);
  }


    if (loading || !trip) {
    return (
      <GuestInformationContainer>
      <div className="accountBody">
        {/* <div className="loading">Loading...</div> */}
      </div>
      </GuestInformationContainer>
    );
  }

   
    const bookings = trip.bookings;


    const guestsArray = Object.keys(bookings).map((index) => {
      
        const checkInDate = moment (bookings[index].checkInDate).format("ddd DD MMM h:mm A");
        const checkOutDate = moment (bookings[index].checkOutDate).format("ddd DD MMM h:mm A");
        let booking_room_type = bookings[index]?.roomBookings[0].room.roomType.name + " - " + bookings[index]?.roomBookings[0].room.roomType.bed + " " + (bookings[index]?.roomBookings[0].room.roomType.hasWindows ? "w/ window" : "w/ skylight");
       
        if (bookings[index].status == "CANCELLED" || bookings[index].isDeleted) {
          return null;
        }


        var addClass1 = "";
        var addClass2 = "";


        if (bookings[index].status == "CHECKED_IN" || bookings[index].isDeleted) {
          addClass1 = "markCheckedIn";
          addClass2 = ""
        }
  
        if (bookings[index].status == "CHECKED_OUT" || bookings[index].isDeleted) {
          addClass1 = "";
          addClass2 = "markCheckedOut"
        }

        return (
          <div className="guestsTableContent" key={index}>
            <div className="col name">{bookings[index].fullName}</div>
            <div className="col">{bookings[index].phoneNumber}</div>
            <div className="col">{booking_room_type}</div>
            <div className="col CheckInCheckOutCol">
            <div className={`CheckInCheckOut ${addClass1}`}>
              <Icon width={11} height={13} className="checkIcon">
                  <CheckInIcon />
              </Icon>
             {checkInDate}</div>
             <div className={`CheckInCheckOut ${addClass2}`}>
              <Icon width={11} height={13} className="checkIcon">
                  <CheckOutIcon />
              </Icon>
                {checkOutDate}</div>
            </div>
            <div className="col">
            {/* {bookings[index].status === "CHECKED_OUT" ? (
                <span className="edit disabledCheckedOut">Edit</span>
              ) : (
                <a
                  href={`/account/bookings/editGuestInfo/${groupId}/${parseInt(index, 10) + 1}`}
                  className="edit"
                >
                  Edit
                </a>
              )} */}
              <a
                className={`edit ${bookings[index].status === "CHECKED_OUT" ? 'disabledCheckedOut' : ''}`}
                onClick={(e) => {
                  if (bookings[index].status !== "CHECKED_OUT") {
                    smoothNavigate(history, `/account/bookings/editGuestInfo/${groupId}/${parseInt(index, 10) + 1}`)
                  }
                }}
              >
                Edit
              </a>
            </div>
          </div>
        );
      })

  

  return (
    <GuestInformationContainer>
{
    processAlerts && (
        <>
        <AlertMessage
        showMessage={messageState.isVisible}
        //messageType = 'error'
        onClose={() => closeMessage()} // Changing state on close
    >
        {messageState.text}
    </AlertMessage>
    
    <AlertMessage
        showMessage={errorState.isVisible}
        messageType = 'error'
        onClose={() => closeError()} // Changing state on close
    >
        {errorState.text}
    </AlertMessage>
        </>
    )
}

      <div className="accountBody">
        <div className="cardContent">
          <a id="guestinfo"></a>
          <h2 className="guestsHeader">Guest Information</h2>
          <div className="guestsTableHeader">
            <div className="col">GUEST NAME</div>
            <div className="col">PHONE NUMBER</div>
            <div className="col">ROOM TYPE</div>
            <div className="col">CHECK-IN/CHECK-OUT</div>
            <div className="col">ACTION</div>
          </div>
            { guestsArray}
            
                  {
                      showDone && (
                          <StyledButton
                              width="84px"
                              height="48px"
                              className="doneButton"
                              onClick={() => {
                                  history.push(`/account/bookings/${groupId}`);
                              }}
                          >
                              Done
                          </StyledButton>
                      )
                  }
        </div>

      </div>


    </GuestInformationContainer>
  );
};

export default GuestInformation;
