import React, { FC, useState, useEffect } from 'react';
import { AccountContentContainer } from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import Checkbox from 'components/Checkbox'
import arrowImg from 'assets/images/str-arrow.svg';
import { CalendarIcon } from "assets/icons";
import StyledButton from 'components/Button';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { useParams } from 'react-router-dom';
import StyledDatePicker from "components/SummaryDatePicker";

const ModifyDatesContent: FC = () => {
  const debug = false;


  const { trips, loading} = useLoadTrips()
  
  const dateRange = ['2025-04-15T12:00:00.000Z', '2025-04-19T08:00:00.000Z'];

  const { groupId } = useParams<{groupId: string}>()
  const { bookingId } = useParams<{bookingId: string}>()
  const trip = trips[groupId]

  const booking = trip?.bookings?.find(b => b.bookingId === bookingId) || null;

  const checkInDateFormatted = booking?.checkInDate
  ? new Date(booking.checkInDate).toLocaleDateString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "short",
    })
  : null;

const checkOutDateFormatted = booking?.checkOutDate
  ? new Date(booking.checkOutDate).toLocaleDateString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "short",
    })
  : null;



  if (debug) {
    console.log ("groupId (from url): ", groupId);
    console.log ("bookingId (from url): ", bookingId);
    console.log ("Currnet booking: ", booking);
  }

  const [chekIn, setCheckIn] = useState(checkInDateFormatted);
  const [chekOut, setCheckOut] = useState(checkOutDateFormatted);
  const [applyThisChangeToAllRooms, setApplyThisChangeToAllRooms] = useState(false);

  
  useEffect(() => {
    setCheckIn (checkInDateFormatted);
    setCheckOut (checkOutDateFormatted);

  }, [checkInDateFormatted, checkOutDateFormatted]);


  const handleDatesChange = () => {

  }

  const handleDatesUpdate = () => {

  }


  return (
    <AccountContentContainer>
      <div className="cardContent">
      <StyledDatePicker label="DATES" dateRange={dateRange} />
        <div className="datesText">
          Select new check-in and check-out dates for your booking. Please note that changing your dates may affect the nightly rate based on availability and demand.
        </div>
        <div className="bookingDates">
          <div className="checkInOut">
          <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="CHECK-IN DATE"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={errorMessageDateOfBirth}
              // errorMessage={errorMessageDateOfBirthText}
              value={chekIn}
              // onChange={handleDateChange}
              onClick={handleDatesChange}
              // onFocus={handleFocus}
              // onKeyDown={handleKeyDown} // Clear the field on the first input
              // onMouseOver={handleMouseOver} 
              // onMouseOut={handleMouseOut}
              // maxLength={11}

              inputHeight="48px"
              
          />
        <CalendarIcon
        className="calendarIcon"
          onClick={handleDatesChange}
        />
          </div>
          <div className="arrow">
            <img src={arrowImg} alt={"Arrow"} width="34" height="9" />
          </div>
          <div className="checkInOut">
          <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="CHECK-OUT DATE"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={errorMessageDateOfBirth}
              // errorMessage={errorMessageDateOfBirthText}
              value={chekOut}
              // onChange={handleDateChange}
              onClick={handleDatesChange}
              // onFocus={handleFocus}
              // onKeyDown={handleKeyDown} // Clear the field on the first input
              // onMouseOver={handleMouseOver} 
              // onMouseOut={handleMouseOut}
              // maxLength={11}

              inputHeight="48px"
              marginBottom="20px"
              
          />
        <CalendarIcon
        className="calendarIcon"
          onClick={handleDatesChange}
        />
          </div>
        </div>
        <Checkbox
            className="accountCheckbox"
            checked={applyThisChangeToAllRooms}
            //error={termsOfServiceError}
            borderColor={colors.labelGrey}
            label="Apply this change to all rooms"
            onChange={() => {
              setApplyThisChangeToAllRooms(!applyThisChangeToAllRooms);   
            }}
            checkboxScale={1.2}
          />
            <div className="buttonContainer">
              <div className="cancelBt">Cancel</div>
              <StyledButton
                width="157px"
                height="48px"
                // margin='32px 0 0 0'
                onClick={() => {
                  handleDatesUpdate()
                }}
              >
                Modify Booking
              </StyledButton>
            </div>
      </div>
    </AccountContentContainer>
  );
};

export default ModifyDatesContent;
