import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const GuestInformationContainer = styled(BasicSectionContainer)`

  .accountBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    gap: 32px;
    .loading {
      padding: 100px;
      color: ${colors.borderGrey};
      font-size: 30px;
    }
    .cardContent {
      min-width: 260px;
      -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      border: 1px solid ${colors.accounBorderColor};
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: stretch;
      flex: 1;
      color: ${colors.commonBlack};
      position: relative;


      .guestsHeader {
         font-size: 24px;
         font-weight: 600;
         line-height: 24px;
         margin-bottom: 32px;
       }

      .guestsTableHeader {
        justify-items: stretch;
        width: 100%;
        display: grid;
        grid-template-columns: 219fr 238fr 258fr 258fr 63fr;
        font-size: 15px;
        font-weight: 600;
        color: ${colors.black};
        padding: 20px 0;
        border-bottom: 1px solid ${colors.black};
        border-top: 1px solid ${colors.black};
        flex: 1;
        .col {
          text-align: left;
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
          padding-right: 10px;
        }
      }

      .guestsTableContent {
        justify-items: stretch;
        width: 100%;
        display: grid;
        grid-template-columns: 219fr 238fr 258fr 258fr 63fr;
        font-size: 16px;
        font-weight: 600;
        color: ${colors.almostBlack};
        padding: 20px 0;
        border-bottom: 1px solid ${colors.borderGrey2};
        .disabledCheckedOut {
           color: ${colors.borderGrey3}!important;
           cursor: not-allowed!important;
           text-decoration: underline!important;
        }
        .CheckInCheckOut {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

        }
        .CheckInCheckOutCol {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .markCheckedIn {
          color: ${colors.optionBackground}!important;
          .checkIcon {
            fill: ${colors.optionBackground}!important;
          }
        }
        .markCheckedOut {
          color: ${colors.red}!important;
          .checkIcon {
            fill: ${colors.red}!important;
          }
        }


         .col {
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          padding-right: 10px;
        }
        .name {
          font-weight: 400;
        }
        .edit {
          color: ${colors.hoverBlue};
          text-decoration: underline;
          cursor: pointer;
        }
        }

        .doneButton {
            margin-top: 32px;
            align-self: flex-end;
        }

    }
  }

`;
