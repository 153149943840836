import React, { useState } from 'react';
import FormTextField from 'components/FormTextField';
import StyledButton from 'components/Button';
import { CancelModalContainer } from './style';
import { CrossIcon } from 'assets/icons';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { cancelReasonModalOpenState, cancelGroupId, cancelBookingId, cancelBookingFee, canceledBookingIdState, canceledGroupIdState} from 'states/ModalStates/atoms';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import { useMutation } from '@apollo/client';
import { DELETE_GROUP_BY_GROUP_ID, DELETE_BOOKING_BY_BOOKING_ID } from 'graphql/service';
import { useHistory } from 'react-router-dom';
import { reloadTrips } from  'states/CommonStates/atoms';

const CancelReasonModalBody: React.FC = () => {


  const debug = true;

  const setOpenCancelReasonModal = useSetRecoilState(cancelReasonModalOpenState)
  const [groupId, setCancelModalGroupId] = useRecoilState(cancelGroupId)
  const [bookingId, setCancelModalBookingId] = useRecoilState(cancelBookingId)
  const [cancellationReason, setCancellationReason] = useState('')
  const [cancelError, setCancelError] = useState(false)
  const [cancelModalBookingFee, setCancelModalBookingFee] = useRecoilState(cancelBookingFee)

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);

  const [canceledBookingId, setCanceledBookingId] = useRecoilState(canceledBookingIdState);
  const [canceledGroupId, setCanceledGroupId] = useRecoilState(canceledGroupIdState);

  const [reloadTripsNow, setReloadTripsNow] = useRecoilState(reloadTrips);

  const history = useHistory()

  const [deleteGroupByGroupId] = useMutation(
    DELETE_GROUP_BY_GROUP_ID,
    {
      onCompleted: (data) => {
        
        setCancelModalGroupId('')
        setCancelModalBookingId('')
        setOpenCancelReasonModal(false)
        setCancelModalBookingFee('')

        if(data.deleteGroup.isDeleted === true) {
          setCanceledGroupId (data.deleteGroup.id)
          setMessageState({ isVisible: true, text: 'Your booking has been successfully canceled, and confirmation will be sent to your email.' });
          
          const bookingUrl = '/account/bookings/' + data.deleteGroup.id;

          if (debug) {
            console.log ('bookingUrl: ', bookingUrl);
          }
          setReloadTripsNow(true); // Updating `trips`          
          history.push(bookingUrl);

          if (debug) {
            console.log('Group cancel successfully, data:', data)
          }
        } else {
          const content = 'Cancel failed'
          console.log(content)
          setErrorState({ isVisible: true, text: 'Cancel failed' });
        }
      },
      onError: (error) => {
        //const content = error.message
        if (debug) {
          console.log('Cancel failed with error: ', error)
        }

        setCancelModalGroupId('')
        setCancelModalBookingId('')
        setOpenCancelReasonModal(false)
        setCancelModalBookingFee('')

        setErrorState({ 
          isVisible: true, 
          text: `Cancel failed with error: ${error.message || 'Unknown error'}` 
        });
      }
    }
  )

  const [deleteBookingByBookingId] = useMutation(
    DELETE_BOOKING_BY_BOOKING_ID,
    {
      onCompleted: (data) => {

        setCancelModalGroupId('')
        setCancelModalBookingId('')
        setOpenCancelReasonModal(false)
        setCancelModalBookingFee('')

        if(data.deleteBooking.status === 'CANCELLED') {
          setCanceledGroupId (data.deleteBooking.id)
          setMessageState({ isVisible: true, text: 'Your room has been successfully removed, and confirmation will be sent to your email. ' });
          if (debug) {
            console.log('Room cancel successfully, data:', data)
          }
          setReloadTripsNow(true); // Updating `trips`
        } else {
          const content = 'Room Cancel failed'
          if (debug) {
            console.log('Room Cancel failed')
          }
          setErrorState({ isVisible: true, text: 'Cancel failed' });
        }
      },
      onError: (error) => {

        setCancelModalGroupId('')
        setCancelModalBookingId('')
        setOpenCancelReasonModal(false)
        setCancelModalBookingFee('')

        //const content = error.message
        if (debug) {
          console.log('Cancel failed with error: ', error)
        }

        setErrorState({ 
          isVisible: true, 
          text: `Cancel failed: ${error.message || 'Unknown error'}` 
        });

      }
    }
  )

  const handleCancelBooking = () => {
    if(cancellationReason.length>0) {
      if(groupId.length>0) {
        deleteGroupByGroupId({
          variables: {
            groupId,
            input: {
              cancellationReason: cancellationReason,
              cancellationOptionIds: ["cf8c0cda-2635-47bf-8ebc-476c8b7a76be"]
            }
          },
        })
      } else if (bookingId.length>0) {
        deleteBookingByBookingId({
          variables: {
            bookingId,
            input: {
              cancellationReason: cancellationReason,
              cancellationOptionIds: ["cf8c0cda-2635-47bf-8ebc-476c8b7a76be"]
            }
          },
        })
      } else {
        if (debug) {
          console.log('Cancel failed ')
        }
      }
    } else {
      setCancelError(true)
    }
  }

  return (
    <CancelModalContainer>
      <div
        className="closeButton"
        onClick={() => {
          setOpenCancelReasonModal(false);
        }}>
        <CrossIcon />
      </div>
      <div className="cardTitle">Cancel your booking</div>
      <div className="contentTitle">Please let us know why you’re cancelling your booking. </div>
      <div className="description">
        Your feedback is important to us and helps us improve our services. How could we have made your experience better? <span className='warning'>*</span>
      </div>
      <div className='textareaContainer'>
        <FormTextField
          className="textField"
          maxRows={6}
          minRows={6}
          multiline
          error={cancelError}
          value={cancellationReason}
          onChange={(e) => {
            setCancellationReason(e.target.value);
            setCancelError(false);
          }}
        />
      </div>
      <div className='buttonContainer'>
        <div
          className='link'
          onClick={handleCancelBooking}
        >
           {groupId.length>0 ? "Cancel Booking" : "Remove Room"}
        </div>
        <StyledButton
          width="142px"
          height="48px"
          onClick={() => setOpenCancelReasonModal(false)}
        >
          Keep {groupId.length>0 ? "Booking" : "Room"}
        </StyledButton>
      </div>
    </CancelModalContainer>
  );
};

export default CancelReasonModalBody
