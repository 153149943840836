import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const AccountContentContainer = styled(BasicSectionContainer)`

    .cardContent {

      .datesText {
      font-size: 16px;
      font-weight: 400;
      color: ${colors.commonBlack};
      line-height: 24px;
    }
    .bookingDates {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      gap: 38px;
      margin-top: 32px;
      .checkInOut {
        position: relative;
        flex: 1;
        .calendarIcon {
          position: absolute;
          top: 35px;
          right: 10px;
        }
      }
    }
    .buttonContainer {
        margin-top: 32px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      align-content: center;
      gap: 24px;
      .cancelBt {
        font-size: 16px;
        font-weight: 700;
        color:  ${colors.hoverBlue};
        text-decoration: underline;
        cursor: pointer;
      }
    } 

      }
`;
