import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userEmailSubscriptionState } from 'states/CheckOutStates/atoms'
import { useMutation, useLazyQuery } from '@apollo/client';
import { DASHBOARD_CREATE_EMAIL_SUBSCRIPTION, DASHBOARD_EMAIL_SUBSCRIPTIONS_BY_USER, DASHBOARD_EMAIL_UNSUBSCRIPTION, DASHBOARD_EMAIL_RESUBSCRIPTION} from 'graphql/service';

/**
 * A custom hook for Personal details Email Screen to load and edit email subscriptions
 */
export const useEmailSubscription = () => {

  const debug = false;
  
  const userId = localStorage.getItem('userId');

// 
// This is current e-mail subscription we use

//       "subscriptionTypes": [
//           {
//               "id": "5f705113-b914-4cee-b503-72c476ae27ee",
//               "type": "general",
//               "createdAt": "2024-07-29T14:39:00.816Z",
//               "updatedAt": "2024-07-29T14:39:00.817Z"
//           }
//       ]

const generalSubscriptionTypeId = "5f705113-b914-4cee-b503-72c476ae27ee";

  if (debug) {
    console.log ("userId = ", userId);
  }
  
  const userEmailSubscription = useRecoilValue(userEmailSubscriptionState);
  const setUserEmailSubscriptionState = useSetRecoilState(userEmailSubscriptionState);

  
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: '',
  });

  const [suscessState, setSuscessState] = useState({
    isSuscess: false,
    message: '',
  });


  const [dashboardCreateEmailSubscription] = useMutation(DASHBOARD_CREATE_EMAIL_SUBSCRIPTION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('CreateEmailSubscription data =', data);
      }
      setUserEmailSubscriptionState((prevState)=>({
        ...prevState,
        subscriptionID: data.createEmailSubscription.id,
        isUserSubscribed: true
      }));

      if (debug) {
        console.log ('!!! Email subscription created !!!');
      };

      setSuscessState({
        isSuscess: true,
        message: "Now you will receive occasional marketing texts from Rook Hotel",
      });

    },
    onError: (error) => {
      const content = error.message;
      
      setErrorState({
        hasError: true,
        message: error.message,
      });

      if (debug) {
        console.log('CreateEmailSubscription error = ', error);
      }
    },
  });

  const [dashboardEmailUnsubscription] = useMutation(DASHBOARD_EMAIL_UNSUBSCRIPTION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('EmailUnsubscriptiondata =', data);
      }
      setUserEmailSubscriptionState((prevState)=>({
        ...prevState,
        isUserSubscribed: false
      }));

      if (debug) {
        console.log ('!!! Email unsubscribed !!!');
      }

      setSuscessState({
        isSuscess: true,
        message: "Now you will not receive occasional marketing texts from Rook Hotel",
      });
    },
    onError: (error) => {
      const content = error.message;

      setErrorState({
        hasError: true,
        message: error.message,
      });
      if (debug) {
        console.log('EmailUnsubscription error = ', error);
      }
    },
  });

  const [dashboardEmailResubscription] = useMutation(DASHBOARD_EMAIL_RESUBSCRIPTION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('EmailResubscription data =', data);
      }
      setUserEmailSubscriptionState((prevState)=>({
        ...prevState,
        isUserSubscribed: true
      }));

      if (debug) {
        console.log ('!!! Email Resubscription !!!');
      }

      setSuscessState({
        isSuscess: true,
        message: "Now you will receive occasional marketing texts from Rook Hotel",
      });

    },
    onError: (error) => {
      const content = error.message;
        setErrorState({
            hasError: true,
            message: error.message,
        });
      if (debug) {
        console.log(error);
      }
    },
  });

  const [fetchDashboardEmailSubscriptionsByUser, { data, loading, error }] = useLazyQuery(
    DASHBOARD_EMAIL_SUBSCRIPTIONS_BY_USER,
    {
      onCompleted: (data) => {
        if (debug) {
          console.log('Loaded data from server =', data);
        }
  
        // Logic for handling subscription data inside onCompleted
        if (data && data.emailSubscriptionsByUser) {
          const activeSubscription = data.emailSubscriptionsByUser.find(
            (subscription) =>
              !subscription.isCancelled &&
              subscription.subscriptionType?.id === generalSubscriptionTypeId
          );
  
          if (activeSubscription) {
            if (debug) {
              console.log('Active SubscriptionID = ' + activeSubscription.id);
            }
            setUserEmailSubscriptionState((prevState)=>({
              ...prevState,
              subscriptionID: activeSubscription.id,
              isUserSubscribed: true,
              loadDataFromServer: false
            }));

            if (debug) {
              console.log ('!!! Active Email Subscription loaded !!!');
            }
          } else {
            // If no active subscriptions matching the criteria or all are cancelled
            const firstInactiveSubscription = data.emailSubscriptionsByUser.find(
              (subscription) =>
                subscription.isCancelled &&
                subscription.subscriptionType?.id === generalSubscriptionTypeId
            );
            if (firstInactiveSubscription) {
                setUserEmailSubscriptionState((prevState)=>({
                ...prevState,
                subscriptionID: firstInactiveSubscription.id, // Set the ID of the first inactive subscription for future resubscription 
                isUserSubscribed: false,
                loadDataFromServer: false
              }));

              if (debug) {
                console.log ('!!! first Inactive Subscription loaded !!!');
              }

              if (debug) {
                console.log('Inactive SubscriptionID = ' + (firstInactiveSubscription?.id || 'None'));
              }

            }
    
            // If no any subscriptions on server
            setUserEmailSubscriptionState((prevState)=>({
              ...prevState,
              loadDataFromServer: false
            }));
          }
        }
      },
      onError: (error) => {
        const content = error.message;
        setErrorState({
            hasError: true,
            message: error.message,
          });
        if (debug) {
          console.log("fetchDashboardEmailSubscriptionsByUser error = ", error);
        }
      },
    }
  );

  const handleFetchEmailSubscriptionsByUser = () => {
    fetchDashboardEmailSubscriptionsByUser({
        variables: {
          userId, 
        },
      });
  };


  const handleSubscription = () => {
    dashboardCreateEmailSubscription({
      variables: {
        input: {
          userId: userId,
          subscriptionTypeIds: [generalSubscriptionTypeId]
        }
      },
    });
  };

  const handleReSubscription = () => {
    dashboardEmailResubscription({
      variables: {
          id: userEmailSubscription.subscriptionID
       },
    });
  };


  const handleUnSubscription = () => {
    dashboardEmailUnsubscription({
      variables: {
          id: userEmailSubscription.subscriptionID
      },
    });
  };

  return {
    handleFetchEmailSubscriptionsByUser,
    handleSubscription,
    handleReSubscription,
    handleUnSubscription,
    errorState,
    setErrorState,
    suscessState,
    setSuscessState
  }
}

