import React from 'react';
import StyledButton from 'components/Button';
import { CancelModalContainer } from './style';
import { CrossIcon } from 'assets/icons';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { cancelBookingModalOpenState, cancelReasonModalOpenState, cancelGroupId, cancelBookingId, cancelBookingFee } from 'states/ModalStates/atoms';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import moment from 'moment';
import { useParams } from 'react-router-dom';


const CancelBookingModalBody: React.FC = () => {

  const debug = true;

  const setOpenCancelBookingModal = useSetRecoilState(cancelBookingModalOpenState)
  const setOpenCancelReasonModal = useSetRecoilState(cancelReasonModalOpenState)
  const groupId = useRecoilValue(cancelGroupId)
  const bookingId = useRecoilValue(cancelBookingId)
  // const cancellationFee = useRecoilValue(cancelBookingFee)

  const { groupId:groupIdForCurrentBooking } = useParams<{ groupId: string }>()

  const { trips } = useLoadTrips()
  const trip = trips[groupId]

  const cancellationFeeChargeDateTime = moment(trip?.bookings?.reduce((min, current) => {
    return current.checkInDate < min? current.checkInDate: min;
  }, trip?.bookings[0]?.checkInDate)).add(-1, 'day').format('ddd DD MMM, h:mm A')


  const totalCancellationFee = trip?.bookings.reduce((sum, booking) => {
    if (!booking.isDeleted) {
        return sum + (booking.cancellationFee ?? 0);
    }
    return sum;
  }, 0);


  const cancellationFee = trips[groupIdForCurrentBooking]?.bookings?.find(booking => booking?.bookingId === bookingId)?.cancellationFee ?? 0;


  if (debug) {
    console.log ("Cancel groupId = ", groupId);
    console.log ("Cancel bookingId = ", bookingId);
    console.log ("groupIdForCurrentBooking = ", groupIdForCurrentBooking);
    console.log ("totalCancellationFee = ", totalCancellationFee);
    console.log ("Bookings = ", trips[groupIdForCurrentBooking]?.bookings);
    console.log ("cancellationFee = ", trips[groupIdForCurrentBooking]?.bookings[bookingId]);
  }


  // const cancellationFee = trip?.bookings?.reduce((prev, current)=> {
  //     return (current.cancellationFee ?? 0) + prev
  // }, 0)

  const handleCancelBooking = () => {
    setOpenCancelBookingModal(false)
    setOpenCancelReasonModal(true)
  }

  return (
    <CancelModalContainer>
      <div
        className="closeButton"
        onClick={() => {
          setOpenCancelBookingModal(false);
        }}>
        <CrossIcon />
      </div>
      <div className="cardTitle">Cancel your booking</div>
      <div className="contentTitle">{groupId.length>0 ? "Are you sure you want to cancel this booking?" : "Are you sure you want to remove this room from your booking?"}
        </div>
      <div className="description">
        {`This booking was made with a flex rate plan. If you cancel before ${cancellationFeeChargeDateTime} you will not incur any charges. If you cancel after this date there will be a fee of $${ groupId.length>0 ? totalCancellationFee : cancellationFee}.`}
      </div>
      <div className='buttonContainer'>
        <div
          className='link'
          onClick={handleCancelBooking}
        >
           {groupId.length>0 ? "Cancel Booking" : "Remove Room"}
        </div>
        <StyledButton
          width="142px"
          height="48px"
          onClick={() => setOpenCancelBookingModal(false)}
        >
          Keep {groupId.length>0 ? "Booking" : "Room"}
        </StyledButton>
      </div>
    </CancelModalContainer>
  );
};

export default CancelBookingModalBody
