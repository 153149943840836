import React, { FC, useState } from "react";
import { RedCloseIcon } from "assets/icons";
import StyledButton from "components/Button";
import FormInputField from "components/FormInputField";
import Modal from "components/Modal";
import Icon from "components/Icon";
import {
  DecreaseIcon,
  IncreaseIcon,
  EditPencilIcon,
} from "assets/icons";
import StyledDatePicker from "components/SummaryDatePicker";
import { useRecoilState, useRecoilValue } from "recoil";
import { dateRangeState, guestQuantityState, roomQuantityState } from "states/SearchBarStates/atoms";
import { addOnOptionsState } from "states/OptionStates/atoms";
import { selectedRooms, couponState, couponSuccessState, ratePlanChoice } from 'states/CheckOutStates/atoms';
import { ADVANCED, RESERVATION, ADVANCED_RATE } from 'constants/ratePlanId';
import { dateDiff } from 'utils/util';
import {
  SubtotalContainer,
  BottomButtonContainer,
  CouponContainer,
  SelectRoomContainer,
  RatePlanContainer,
  AddOnContainer,
  SummaryContainer,
  SummaryContent,
} from "./style";
import { useGetBookingAddOns } from "customHooks/useGetBookingAddOns";
import { useLazyQuery } from "@apollo/client";
import { APPLY_COUPON } from "graphql/service";
import { useGetAvailableRooms } from 'customHooks/useGetAvailableRooms';
import { colors } from 'constants/colors'

type BookingSummaryModalProps = {
  showAddOn?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BookingSummaryModal: FC<BookingSummaryModalProps> = ({ showAddOn, open, setOpen }) => {

  const debug = true;

  const persistSelectedRooms = useRecoilValue(selectedRooms)
  const dateRange = useRecoilValue(dateRangeState);

  if (debug) {
    console.log ("dateRange = ", dateRange);
  }

  const [guestQuantity, setGuestQuantity] = useRecoilState(guestQuantityState);
  const [roomQuantity, setRoomQuantity] = useRecoilState(roomQuantityState);
  const [addOnOptions, setAddOnOptions] = useRecoilState(addOnOptionsState);
  const [ratePlanId, setRatePlanId] = useRecoilState(ratePlanChoice)
  const bookingAddOns = useGetBookingAddOns()

  const { searchResult } = useGetAvailableRooms()
  const availableRooms = searchResult.find((roomType) => roomType.roomTypeId === persistSelectedRooms.roomTypeId)?.availableRooms || 0

  const [coupon, setCoupon] = useRecoilState(couponState)
  const [couponInput, setCouponInput] = useState(coupon.couponCode || '')
  const [couponError, setCouponError] = useState(false)
  const [couponSuccess, setCouponSuccess] = useRecoilState(couponSuccessState)

  const successMessage = 'Success! Coupon code applied.'
  const errorMessage = 'Coupon code is invalid'

  const [applyCoupon] = useLazyQuery(APPLY_COUPON, {
    onCompleted: (couponQueryData) => {
      if(couponQueryData.couponByCode?.isActive) {
        setCouponError(false)
        setCouponSuccess(true)
        setCoupon(couponQueryData.couponByCode)
        localStorage.setItem("coupon", JSON.stringify(couponQueryData.couponByCode))
      } else {
        setCouponError(true)
        setCouponSuccess(false)
        setCouponInput('')
      }
    },
    onError: (error) => {
      setCouponError(true)
      setCouponSuccess(false)
      setCouponInput('')
    }
  })

  const nights = dateDiff(new Date(dateRange[0]), new Date(dateRange[1]))
  const roomFee = (persistSelectedRooms.pricePerNight || 0) * nights * roomQuantity
  const addOnFee = bookingAddOns.reduce((acc, addOn) => {
    if (addOnOptions.includes(addOn.addOnId)) {
      if(addOn.isOneTimePriced) {
        return acc + (addOn.pricePerNight || 0) * roomQuantity
      } else {
        return acc + (addOn.pricePerNight || 0) * nights * roomQuantity
      }
    }
    return acc
  }
  , 0)

  const couponDiscount = coupon.rateType==='FIXED_RATE'? (coupon.discount || 0): (coupon.discount || 0) / 100 * roomFee
  const discountedRoomFee = coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1): roomFee * (1 - (coupon.discount || 0) / 100) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1)
  const subtotal = discountedRoomFee + addOnFee

  const handleCouponApply = () => {
    if (couponInput.length) {
      applyCoupon({
        variables: {
          couponCode: couponInput.toUpperCase()
        }
      })
    }
  }

  const addOnOptionsArray = bookingAddOns.map((addOn) => {
    if(addOnOptions.includes(addOn.addOnId)) {
      return (
        <div className="addOnOption" key={addOn.addOnId}>
            <div className="row">
              <span className="text">{addOn.label?.toUpperCase()}</span>
              <span
                className="remove"
                onClick={() => {
                  const index = addOnOptions.indexOf(addOn.addOnId)
                  const newAddOnOptions = [...addOnOptions]
                  newAddOnOptions.splice(index, 1)
                  setAddOnOptions(newAddOnOptions)
                  localStorage.setItem("addOnOptions", JSON.stringify(newAddOnOptions))
                }}
              >
                Remove
                <RedCloseIcon className="removeIcon" />
              </span>
            </div>
            <div>${addOn.pricePerNight.toFixed(2) + (addOn.isOneTimePriced? '':' / day')}</div>
        </div>
      )
    }
    return null
  })


  return (
    <Modal open={open} mobile>
      <SummaryContainer>
        <div className="header">
          <h1 className="modal-title">Booking Summary</h1>
          <div
            className="close-icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <span>Close</span>
            <RedCloseIcon />
          </div>
        </div>
        <SummaryContent>
          <StyledDatePicker label="DATES" dateRange={dateRange} />
          <SelectRoomContainer>
            <div className="content">
              <div className="guestsOption">
                <div className="text">
                  <div className="optionTitle">GUESTS</div>
                  <div className="optionDescription">
                    How many people are traveling?
                  </div>
                </div>
                <div className="optionButtons">
                  <Icon width={30} height={30}>
                    <DecreaseIcon
                      className={guestQuantity <= 1? "disabled" : ""}
                      onClick={() => {
                        if(guestQuantity > 1) {
                          localStorage.setItem('guestQuantity', (guestQuantity - 1).toString());
                          setGuestQuantity(guestQuantity - 1);
                          if(roomQuantity > guestQuantity - 1) {
                            localStorage.setItem('roomQuantity', (guestQuantity - 1).toString());
                            setRoomQuantity(guestQuantity - 1);
                          }
                        }
                      }}
                    />
                  </Icon>
                  <div className="quantity">{guestQuantity}</div>
                  <Icon width={30} height={30}>
                    <IncreaseIcon
                      className={guestQuantity >= (availableRooms>0? 2 * Math.min(availableRooms, 9): 9) ? "disabled": ""}
                      onClick={() => {
                        if(guestQuantity < (availableRooms>0? 2 * Math.min(availableRooms, 9): 9)) {
                          localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                          setGuestQuantity(guestQuantity + 1);
                          if(roomQuantity < Math.ceil((guestQuantity+1)/2)) {
                            localStorage.setItem('roomQuantity', Math.ceil((guestQuantity+1)/2).toString());
                            setRoomQuantity(Math.ceil((guestQuantity+1)/2));
                          }
                        }
                      }}
                    />
                  </Icon>
                </div>
              </div>
              <div className="roomsOption">
                <div className="text">
                  <div className="optionTitle">ROOMS</div>
                  <div className="optionDescription">Max 2 guests per room</div>
                </div>
                <div className="optionButtons">
                  <Icon width={30} height={30}>
                    <DecreaseIcon
                      className={roomQuantity <= Math.ceil(guestQuantity/2)? "disabled": ""}
                      onClick={() => {
                        if(roomQuantity > Math.ceil(guestQuantity/2)) {
                          localStorage.setItem('roomQuantity', (roomQuantity - 1).toString());
                          setRoomQuantity(roomQuantity - 1);
                        }
                      }}
                    />
                  </Icon>
                  <div className="quantity">{roomQuantity}</div>
                  <Icon width={30} height={30}>
                    <IncreaseIcon
                      className={roomQuantity >= (availableRooms>0? Math.min(availableRooms, 9): 9)? "disabled": ""}
                      onClick={() => {
                        if(roomQuantity < (availableRooms>0? Math.min(availableRooms, 9): 9)) {
                          localStorage.setItem('roomQuantity', (roomQuantity + 1).toString());
                          setRoomQuantity(roomQuantity + 1);
                          if(roomQuantity===guestQuantity) {
                            localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                            setGuestQuantity(guestQuantity + 1);
                          }
                        }
                      }}
                    />
                  </Icon>
                </div>
              </div>
            </div>
          </SelectRoomContainer>
          {couponSuccess && (
            <div className="breakDownRow">
              <div className="breakDownLabel">
                <span className="warning">{`Coupon (${coupon.rateType==='FIXED_RATE'? '$'+ coupon.discount + ' off': coupon.discount + '% off'})`}</span>
              </div>
              <div className="breakDownValue">
                <span className="warning">-${couponDiscount.toFixed(2)}</span>
              </div>
            </div>
          )}
          <RatePlanContainer>
            <div className="infoLabel">RATE</div>
            <div className="infoValue">
              <span>{ratePlanId === ADVANCED? 'Advanced Purchase (10% off)': 'Pay at Check-in'}</span>
              <EditPencilIcon onClick={()=>{
                if(ratePlanId === ADVANCED) {
                  setRatePlanId(RESERVATION)
                  localStorage.setItem("ratePlanId", RESERVATION)
                } else {
                  setRatePlanId(ADVANCED)
                  localStorage.setItem("ratePlanId", ADVANCED)
                }
              }}/>
            </div>
          </RatePlanContainer>
          {showAddOn && (
            <AddOnContainer>
              {addOnOptionsArray}
            </AddOnContainer>
          )}
          <CouponContainer>
            <FormInputField
              label="COUPON CODE"
              error={couponError}
              errorMessage={errorMessage}
              success={couponSuccess}
              successMessage={successMessage}
              borderColor={colors.signInBorderColor}
              onChange={(e) => {
                setCouponInput(e.target.value)
                if(couponError) {
                  setCouponError(false)
                }
                if(couponSuccess) {
                  setCouponSuccess(false)
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCouponApply()
                }
              }}
              value={couponInput}
              inputHeight="43px"
            />
            <StyledButton
              className="applyCouponButton"
              width="87px"
              height="43px"
              disabled={!couponInput.length}
              onClick={handleCouponApply}
            >
              Apply
            </StyledButton>
          </CouponContainer>
          <SubtotalContainer>
            <span className="text">SUBTOTAL: </span>
            <span className="total">{` CAD ${subtotal.toFixed(2)}`}</span>
          </SubtotalContainer>
          <BottomButtonContainer>
            <StyledButton
              className="searchBarButton"
              width="100%"
              height="59px"
              fontSize={18}
              onClick={() => {}}
            >
              Continue Booking
            </StyledButton>
          </BottomButtonContainer>
        </SummaryContent>
      </SummaryContainer>
    </Modal>
  );
};

export default BookingSummaryModal;
