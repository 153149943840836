import styled from "styled-components";
import { colors } from "constants/colors";
import { BasicSectionContainer } from "../../../style";
import { styleSizes } from 'constants/styleSizes';

export const SecurityContentContainer = styled(BasicSectionContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  gap: 32px;
  overflow: visible;

  .accountBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    gap: 32px;
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      margin-bottom: 100px;
    }

    .cardContent {

      /* Styles for .cardContent inside BasicSectionContainer */

      .errorMessageForgotPassword {
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: ${colors.red};
        font-size: 16px;
        font-weight: 600;
        white-space: normal;
        overflow: visible;
        text-overflow: unset;
        text-align: left;
        margin-top: -10px;
        margin-bottom: 15px;
      }
      .sucsessMessageForgotPassword {
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: ${colors.questionLinkText};
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: -10px;
        margin-bottom: 15px;
      }
      .secHeader {
        font-size: 24px;
        font-weight: 600;
        color: ${colors.almostBlack};
        padding: 0;
        margin: 3px 0 17px 0;
      }
      .description {
        font-size: 16px;
        line-height: 24px;
        color: ${colors.almostBlack};
      }

      .buttonWithTooltip {
        position: relative;
        overflow: visible;
        align-self: flex-start;
        @media (max-width: ${styleSizes.mobileBreakpoint}) {
          align-self: flex-end;
        }
        .tool {
          position: absolute;
          top: -87px;
          left: 112px;
          @media (max-width: ${styleSizes.mobileBreakpoint}) {
            left: -130px;
          }
        }
        .toolLt {
          display: block;
          @media (max-width: ${styleSizes.mobileBreakpoint}) {
            display: none;
          }
        }
        .toolRt {
          display: none;
          @media (max-width: ${styleSizes.mobileBreakpoint}) {
            display: block;
          }
        }
      }
      .secInput {
        label {
          color: ${colors.secLabel};
        }
        .highLight {
        }
      }
      .passwordContainer {
        //position: relative;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin: 32px 0 0 0;
        @media (max-width: ${styleSizes.mobileBreakpoint}) {
          width: auto;
        }
        .bar {
          color: ${colors.borderGrey3};
          /* div {
            background-color: ${colors.borderGrey3};
          } */
        }

        .passwordStrengthBar {
          width: 340px;
          margin-bottom: 0;
          @media (max-width: ${styleSizes.mobileBreakpoint}) {
            width: auto;
          }
        }
        .passResetPhrase {
          font-size: 16px;
          font-weight: 400;
          color: ${colors.almostBlack};
          line-height: 24px;
          margin-bottom: 28px;
          .resetLink {
            color: ${colors.helpBoxBorderHover};
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .passwordStrengthBarText {
          min-height: 12px;
        }

        .inpuntCont {
          width: 340px;
          position: relative;
          @media (max-width: ${styleSizes.mobileBreakpoint}) {
            width: auto;
          }
          label {
            margin-bottom: 10px;
          }
          .secInput {
            margin-bottom: 14px;
          }
          .warning,
          .success {
            height: auto;
            min-height: 20px;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            margin-top: 7px;
          }
        }
        .passwordStrengthBar {
          margin-top: -7px;
        }

        .oldPasswordLock,
        .oldPasswordUnlock,
        .newPasswordLock,
        .newPasswordUnlock {
          position: absolute;
          top: 47px;
          right: 12px;
          cursor: pointer;
        }
      }

      .buttonContainer {
        position: relative;

        svg {
          position: absolute;
          top: -85px;
          left: 150px;
        }
      }
    }
  }
`;
