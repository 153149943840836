import React, { useEffect, useState } from 'react';
import BookingBanner from 'components/BookingBanner';
import make3 from 'assets/images/make-3.jpg';
import make4 from 'assets/images/make-4.jpg';
import { useGetClosestBookingInfo } from 'customHooks/useGetClosestBookingInfo';
import { useGetBookingGroupStatus } from 'customHooks/useGetBookingGroupStatus';

interface BookingBannerCompleteYourBookingProps {
  groupID?: string; // Optional prop to specify the group ID
}

const BookingBannerCompleteYourBooking: React.FC<BookingBannerCompleteYourBookingProps> = ({ groupID }) => {

  const { loading, bookingInfo: closestBookingInfo } = useGetClosestBookingInfo();

  
// const { currentGroupBookingStatus } = groupID 
//   ? useGetBookingGroupStatus(groupID) 
//   : { currentGroupBookingStatus: null };


  // if (groupID === undefined) {
  //   groupID = '';
  // }
  
  const { currentGroupBookingStatus} =  useGetBookingGroupStatus(groupID);

  const [currentBookingButtonURL, setCurrentBookingButtonURL] = useState('/'); // URL for the "Add Guest Info" button
  const [showBookingBanner, setShowBookingBanner] = useState(false); // Flag to control the visibility of the banner

  

  const debug = false; // Enable debug logging

  useEffect(() => {
    if (groupID) {
      // If the groupID prop is provided, use it to build the button URL
      if (currentGroupBookingStatus === 'INCOMPLETE') {
        setCurrentBookingButtonURL(`/account/bookings/addGuestInfo/${groupID}/1`);
        setShowBookingBanner(true);
        if (debug) {
          console.log ("useGetBookingGroupStatus: ", useGetBookingGroupStatus)
        }
      }
      else {
        setShowBookingBanner(false);
      }
    } else if (closestBookingInfo) {
      // If groupID is not provided, fall back to the closestBookingInfo
      if (debug) {
        console.log('closestBookingInfo: ', closestBookingInfo);
      }

      if (closestBookingInfo.status === 'INCOMPLETE') {
        setCurrentBookingButtonURL(`/account/bookings/addGuestInfo/${closestBookingInfo.groupID}/1`);
        setShowBookingBanner(true);
      }
    } else {
      // If there is no groupID or closestBookingInfo, hide the banner
      if (debug) {
        console.log('No bookings.');
      }
      setShowBookingBanner(false);
    }
  }, [groupID, closestBookingInfo, useGetBookingGroupStatus, currentGroupBookingStatus]); 

  // Do not render the banner if it should not be shown or if data is still loading
  if (!showBookingBanner || loading) return null;

  return (
    <BookingBanner
      buttonText="Add Guest Info"
      buttonUrl={currentBookingButtonURL}
      mainText="Complete Your Booking"
      secondaryText="Make a quicker getaway! Adding guest details will make check-in faster when you arrive."
      image1={make3}
      image2={make4}
      buttonWidth="153px"
    />
  );
};

export default BookingBannerCompleteYourBooking;