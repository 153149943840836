import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../style';
import optionBackground from 'assets/images/optionBackground.svg';

export const CheckOutContentContainer = styled(BasicSectionContainer)`

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .background {
    width: 100%;
    height: 69px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${optionBackground});
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .spinner {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    width: 120px;
    height: 120px;
    z-index: 3000;
    background-color: ${colors.black};
  }

  @media only screen and (max-width: 767px) {

    .background {
      display: none;
    }

    .content {
      flex-direction: column;
    }
  }
`;

export const PaymentSectionContainer = styled(BasicSectionContainer)`
  flex-basis: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 15px;
  margin-top: 45px;

  .ratePlanTitle, .paymentDetailsTitle {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }

  @media only screen and (max-width: 767px) {
    .ratePlanTitle {
      display: none;
    }

    .paymentDetailsTitle {
      font-size: 30px;
      font-weight: bold;
    }
  }
`;

export const PlanSectionContainer = styled(BasicSectionContainer)`
  min-height: 135px;
  max-height: 254px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: column;
  .ratePlanTitle, .paymentDetailsTitle {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .selectionContainer {
    flex: 8;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }


  @media only screen and (min-width: 767px) and (max-width: 1200px) {    
    height: auto;

    .selectionContainer {
      flex: 1;
      width: 100%;
      height: auto;
      flex-direction: column;

      .sectionContainerItem {
        max-width: 83%;
        margin-right: 0;
      }

      p {
        font-size: 12px;
        padding: 0 0 0 40px;
      }

      .title {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const SectionContainerItem = styled.div<{selected?: boolean }>`
  max-width: 511px;
  min-width: 330px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 30px;
  cursor: pointer;

  .inputContainer {
    display: flex;

    .customizedRadio {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid ${colors.signInBorderColor};
      margin: 0 10px 0 0;

      ${({selected}) => selected? `
        ::before {
          content: '';
          position: absolute;
          left: calc(50% - 6px);
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;
          background-color: ${colors.radioGreen};
          border-radius: 50%;
        }
      `: null};
    }

    .title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  p {
    width: 100%;
    font-size: 14px;
    font-style: italic;
    color: ${colors.userSelectionBlack};
  }

`;

export const PaymentInfoContainer = styled.div`
  flex: 1;
  width: 100%;
  min-width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 0px 0px 30px 0;

  .row {
    position: relative;
    width: 100%;
    display: flex;
    column-gap: 16px;

    .emailInput {
      width: 65%;
    }

    .phoneNumberInput {
      width: 35%;
    }
  }

  .infoInputField {
    width: 100%;
    margin-bottom: 20px;

    label {
      display: block;
      font-size: 12px;
      color: ${colors.userSelectionBlack};
      margin-bottom: 5px;
      letter-spacing: 1px;
    }
  }

  @media only screen and (max-width: 1200px) {
    min-width: 100%;

    .row {
      flex-direction: column;

      .emailInput {
        width: 100%;
      }

      .phoneNumberInput {
        width: 60%;
      }
    }
  }

  @media only screen and (max-width: 767px) {

    margin: 0;

    .row {
      .phoneNumberInput {
        width: 60%;
      }
    }
  }
`;

export const SignInModalContainer = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${colors.white};
  border-radius: 32px;
  z-index: 200;
  width: 90%;
  max-width: 348px;
  min-height: 365px;

  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .largeTitle {
    width: 90%;
    font-size: 33px;
    font-weight: bold;
    text-align: center;
    line-height: normal; 
    margin: 30px 0 20px 0;
  }

  .input {
    width: 80%;
    margin: 14px 0px;
  }

  .buttonContainer {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 15px 0 30px 0;
  }

  @media only screen and (max-width: 767px) {

    .largeTitle {
      font-size: 32px;
    }

    .input {
      width: 90%;
    }
  }
`;

export const BookingSummaryContainer = styled.div`
  flex-basis: 40%;
  max-width: 450px;
  min-width: 397px;
  margin: 45px 0 0 20px;

  .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin: 0;

    .title {
      font-size: 26px;
      margin: 0 0 30px 0;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
    }

    .warning {
      color: ${colors.red};
      font-size: 16px;
      font-weight: 600;
    }

    .tooltipContent {
      svg {
        margin: 0 0 0 5px;
      }

      .highLight{
        font-size: 14px;
        font-weight: 600;
        font-style: italic;
        color: ${colors.tooltip};
      }

      .text {
        font-size: 14px;
        color: ${colors.black};
      }
    }

    .infoRow.dates {
      width: 60%;
    }

    .infoRow.quantity {
      width: 40%;
    }

    .infoRow {
      width: 100%;
      margin: 5px 0 20px 0;

      .infoLabel {
        font-size: 14px;
        font-weight: 600;
        color: ${colors.black};
        text-align: left;
        margin: 0 0 5px 0;
      }

      .infoValue {
        font-size: 16px;
        color: ${colors.userSelectionBlack};
        text-align: left;
      }
    }

    .breakDown {
      width: 100%;
      margin: 0 0 12px 0;

      .breakDownTitle {
        font-size: 14px;
        font-weight: 600;
        color: ${colors.black};
        text-align: left;
        margin: 0 0 5px 0;
      }

      .breakDownRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 16px;
        color: ${colors.userSelectionBlack};
        margin-bottom: 8px;

        .breakDownLabel {
          text-align: left;
          width: 70%;

          svg {
            position: relative;
            top: 6px;
            left: 5px;
          }
        }

        .breakDownValue {
          text-align: right;
          width: 30%;
        }
      }
    }

    .totalRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 20px 0;

      .totalLabel {
        text-align: left;
        width: 70%;
        font-size: 14px;
        font-weight: 600;
        color: ${colors.black};
      }

      .totalValue {
        text-align: right;
        width: 30%;
        font-size: 18px;
        font-weight: bold;
        color: ${colors.black};
      }
    }
    .checkOutButton {
        align-self: flex-end;
      }

    .termsOfServiceContainer {
      width: 100%;
      padding: 10px 8px;
      margin: 20px 0;

      .required {
        margin-top: 8px;
        color: ${colors.red};
        font-size: 16px;
      }

      

      .termsOfService {
        display: flex;

        .text{
          margin-left: 5px;

          .plainText {
            font-size: 16px;
            height: 30px;
            line-height: 24px;
            color: ${colors.black};
            cursor: pointer;
          }

          .highLight {
            font-size: 16px;
            height: 30px;
            line-height: 24px;
            font-weight: 400;
            color: ${colors.hoverBlue};
            white-space: nowrap;
            cursor: pointer;
            text-decoration: underline;
          }
        }

      }
    }
  }

  .couponContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    column-gap: 10px;

    label {
      color: ${colors.black};
    }

    .applyCouponButton {
      margin: 5px 0 0 0;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const MobileBookingSummaryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px;
  margin: 0;

  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .bookingDates {
    width: 100%;
  }

  .guestOption, .roomOption {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.checkOutTableTextColor};

    .text {
      .optionTitle {
        font-size: 14px;
        font-weight: 600;
        color: ${colors.black};
      }

      .optionDescription {
        font-size: 16px;
        font-style: italic;
        color: ${colors.grey};
      }
    }

    .optionButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .quantity {
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 21px;
        font-weight: 600;
      }
    }

    .disabled {
      g, line {
        stroke: ${colors.signInBorderColor};
      }
    }
  }

  .addOnOption {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.checkOutTableTextColor};

    .text {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.black};
    }

    .remove {
      cursor: pointer;
      margin: 0 8px 0 0;

      .removeIcon {
        margin-left: 5px;
        height: 10px;
        width: 10px;
      }
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }

  .warning {
    color: ${colors.red};
    font-size: 16px;
    font-weight: 600;
  }

  .infoRow.dates {
    width: 60%;
  }

  .infoRow.quantity {
    width: 40%;
  }

  .infoRow {
    width: 100%;
    margin: 5px 0 20px 0;

    .infoLabel {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.black};
      text-align: left;
      margin: 0 0 5px 0;
    }

    .infoValue {
      font-size: 16px;
      color: ${colors.userSelectionBlack};
      text-align: left;
    }
  }

  .breakDown {
    width: 100%;
    margin: 10px 0 12px 0;

    .breakDownTitle {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.black};
      text-align: left;
      margin: 0 0 5px 0;
    }

    .breakDownRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 16px;
      color: ${colors.userSelectionBlack};
      margin-bottom: 8px;

      .breakDownLabel {
        text-align: left;
        width: 80%;

        svg {
          position: relative;
          top: 6px;
          left: 5px;
        }
      }

      .breakDownValue {
        text-align: right;
        width: 30%;
      }
    }
  }

  .totalRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;

    .totalLabel {
      text-align: left;
      width: 70%;
      font-size: 14px;
      font-weight: 600;
      color: ${colors.black};
    }

    .totalValue {
      text-align: right;
      width: 30%;
      font-size: 18px;
      font-weight: bold;
      color: ${colors.black};
    }
  }

  .termsOfService {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 2px solid ${colors.questionBorder};
      padding: 15px 20px;
      margin: 15px 0;

      .text{
        margin-left: 5px;

        .plainText {
          font-size: 16px;
          height: 30px;
          line-height: 30px;
          color: ${colors.black};
          cursor: pointer;
        }

        .highLight {
          font-size: 16px;
          height: 30px;
          line-height: 30px;
          font-weight: bold;
          color: ${colors.hoverBlue};
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }

  .couponContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    column-gap: 10px;

    label {
      color: ${colors.black};
    }

    .applyCouponButton {
      margin: 5px 0 0 0;
    }
  }

  @media only screen and (min-width: 767px) {
    display: none;
  }
`;

export const RatePlanContainer = styled.div`
  width: 100%;
  padding: 0 0 5px 0;
  border-bottom: 1px solid ${colors.checkOutTableTextColor};
  margin: 10px 0;

  .infoLabel {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
    text-align: left;
    margin: 0 0 5px 0;
  }

  .infoValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: ${colors.black};

    svg {
      cursor: pointer;
    }
  }
`;