import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const AccountContentContainer = styled(BasicSectionContainer)`

.addonsContainer {

  display: flex;
  flex-direction: column;
  gap: 32px;

}
.addonsContainerApplyToAll {
  padding-top: 32px;
}
.addonsChanges {
  min-width: 260px;
    -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    border: 1px solid ${colors.accounBorderColor};
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    flex: 1;
    color: ${colors.commonBlack};
    margin-top: 32px;
    .addonsOverview {
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
      line-height: 18px;
      margin-bottom: 22px;
      margin-top: 5px;
    }
    .addonNameAndPrice {
      margin-bottom: 9px;
    }
    .addonsOverviewNumber{
        font-size: 16px;

      }
    .addonsOverviewTotal {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 5px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 8px;


      .addonsOverviewNumber{
        font-size: 16px;
        font-weight: 700;
      }
      }
      .downloadReceipt {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        justify-content: flex-end;
      }
    .addonsOverviewLine {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 5px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 21px;
      .priceAdd {
          color:  ${colors.optionBackground}
      }

      .priceRemove {
        color:  ${colors.red}
      }
      .addonsOverviewDots {
        flex: 1;
        display: flex;
        align-items: flex-start;
        margin: 10px;
        flex-direction: row;
        margin-left: 10px;
        margin-right: 5px;
      }
      .dashedLine {
        width: 100%; /* The width of the line will match the width of the container */
        height: 1px; /* Height of the line */
        background: repeating-linear-gradient(
          to right,
          ${colors.borderGrey2} 0,
          ${colors.borderGrey2} 1px,
          /* Length of the dash */ transparent 2px,
          transparent 4px /* Length of the space */
        );
        border: none; /* Remove any default border if present */
      }

      .dashedLineRed {
        width: 100%; /* The width of the line will match the width of the container */
        height: 1px; /* Height of the line */
        background: repeating-linear-gradient(
          to right,
          ${colors.red} 0,
          ${colors.red} 1px,
          /* Length of the dash */ transparent 2px,
          transparent 4px /* Length of the space */
        );
        border: none; /* Remove any default border if present */
      }
      .addonsOverviewNumber {
        min-width: 50px;
        text-align: right;
      }
    }
    .addonsOverviewLineRed {
        .addonsOverviewTitle {
          color: ${colors.red};
          font-weight: 600;
        }
        .addonsOverviewNumber {
          color: ${colors.red};
          font-weight: 600;
        }
      }

      .buttonContainer {
        margin-top: 32px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      align-content: center;
      gap: 24px;
      .cancelBt {
        font-size: 16px;
        font-weight: 700;
        color:  ${colors.hoverBlue};
        text-decoration: underline;
        cursor: pointer;
      }
    } 
}

    /* .cardTitle {
      font-size: 24px;
      font-weight: 600;
      color: ${colors.black};
      margin-bottom: 16px;
    }

    .description {
      font-size: 18px;
      color: ${colors.black};
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    } */

`;

