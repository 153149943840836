import { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userPhoneSubscriptionState } from 'states/CheckOutStates/atoms'
import { useMutation, useLazyQuery } from '@apollo/client';
import { DASHBOARD_CHANGE_BOOKING_ADDONS} from 'graphql/service';
import { userInfoState} from 'states/CheckOutStates/atoms'
import { useAuth } from 'customHooks/useAuth';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import { useRecoilState } from 'recoil';
import { reloadTrips } from  'states/CommonStates/atoms';

/**
 * A custom hook to change booking adons
 */


export const useChangeAddons = () => {

  const debug = true;
  
  
  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);
  const setReloadTrips = useSetRecoilState(reloadTrips);



  const [changeAddons] = useMutation(DASHBOARD_CHANGE_BOOKING_ADDONS, {
    onCompleted: (data) => {

      if (debug) {
          console.log ('changeAddons data =', data);
      }

      setMessageState((prevState)=>({
        ...prevState,
        isVisible: true,
        text: "Your booking has been successfully modified."
      }));
      setReloadTrips(true);

    },
    onError: (error) => {
      const content = error.message;
      
      setErrorState((prevState)=>({
        ...prevState,
        isVisible: true,
        text: content
      }));


      if (debug) {
        console.log("changeAddons error =", error);
      }
    },
  });



  const handleChangeAddons = (addonsArray) => {
    changeAddons({
      variables: {
        input: {
          addOns: addonsArray
        }
      },
    });
  };

  return {
    handleChangeAddons
  }
}

