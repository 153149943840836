import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { 
  selectedRooms,
  paymentInfoState,
  userInfoState,
  ratePlanChoice,
  couponState,
  couponSuccessState,
  termsOfServiceState,
  termsOfServiceErrorState
} from 'states/CheckOutStates/atoms';
import { dateRangeState, guestQuantityState, roomQuantityState, isWheelchairAccessibleState } from 'states/SearchBarStates/atoms';
import { addOnOptionsState } from 'states/OptionStates/atoms';
import {
  PaymentSectionContainer,
  PaymentInfoContainer,
  CheckOutContentContainer
} from './style'
import PlanSection from './PlanSection';
import { Grid } from '@material-ui/core';
import FormInputField from 'components/FormInputField';
import PhoneNumberInput from 'components/PhoneNumberInput';
import BookingSummary from './BookingSummary';
import Modal from 'components/Modal';
import SignInModalBody from './SignInModalBody';
import ReactInputMask from 'react-input-mask'
import { useGoogleAutocomplete } from 'customHooks/useGoogleAutocomplete';
import {
  creditCardMask,
  expireDateMask,
  cvvMask,
} from './inputMask/inputMasks';
import { useAuth } from 'customHooks/useAuth';
import { usePaymentForm } from 'customHooks/usePaymentForm';
import { useMutation } from '@apollo/client';
import { CREATE_GROUP_BOOKING } from 'graphql/service';
import { detectCardType } from 'utils/util'
import { GroupTenantBookingInput } from 'types/commonTypes';
import { colors } from 'constants/colors';
import MobileBookingSummary from './MobileBookingSummary';
import greenCheck from 'assets/icons/greenCheck.svg';
import { reloadTrips } from  'states/CommonStates/atoms';
import { isToday } from 'functions/dates';



const CheckOutContent: React.FC = () => {

  const debug = true;

  const history = useHistory()
  const { validToken } = useAuth()
  const [openSignInModal, setOpenSignInModal] = useState(false)

  const persistSelectedRooms = useRecoilValue(selectedRooms)
  const dateRange = useRecoilValue(dateRangeState)

  if (debug) {
    console.log ("dateRange: ",dateRange);
  }

  const roomQuantity = useRecoilValue(roomQuantityState)
  const guestQuantity = useRecoilValue(guestQuantityState)
  const addOnOptions = useRecoilValue(addOnOptionsState)
  //const ratePlanId = useRecoilValue(ratePlanChoice)



  const termsOfServiceChecked = useRecoilValue(termsOfServiceState)
  const [termsOfServiceError, setTermsOfServiceError] = useRecoilState(termsOfServiceErrorState)
  const coupon = useRecoilValue(couponState)
  const couponSuccess = useRecoilValue(couponSuccessState)

  const clearPaymentInfo = useResetRecoilState(paymentInfoState)
  const clearSelectedRooms = useResetRecoilState(selectedRooms)
  const clearRoomQuantity = useResetRecoilState(roomQuantityState)
  const clearGuestQuantity = useResetRecoilState(guestQuantityState)
  const clearDateRange = useResetRecoilState(dateRangeState)
  const clearAddOnOptions = useResetRecoilState(addOnOptionsState)
  const clearIsWheelChairAccessible = useResetRecoilState(isWheelchairAccessibleState)
  const clearRatePlanChoice = useResetRecoilState(ratePlanChoice)
  const clearCouponCode = useResetRecoilState(couponState)
  const clearCouponSuccess = useResetRecoilState(couponSuccessState)

  const [userInfo, setUserInfo] = useRecoilState(userInfoState)
  const [emailError, setEmailError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(userInfo.phoneNumberInput)
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [ratePlanId, setRatePlanId] = useRecoilState(ratePlanChoice)
  if (debug) {
    console.log ("ratePlanId: ",ratePlanId);
  }
  const isWheelchairAccessible = useRecoilValue(isWheelchairAccessibleState)

  const setReloadTrips = useSetRecoilState(reloadTrips);

  useEffect(() => {
    if(phoneNumberValidity) {
      setPhoneNumberError(false)
      setUserInfo((userInfo) => ({...userInfo, phoneNumberInput: phoneNumber}))
    }
  }, [phoneNumber, phoneNumberValidity, setPhoneNumberError, setUserInfo])

  const [creditCardType, setCreditCardType] = useState('')
  const [coverInput, setCoverInput] = useState('')

  const removeSavedInformation = () => {
    clearPaymentInfo()
    clearIsWheelChairAccessible()
    clearSelectedRooms()
    clearRoomQuantity()
    clearGuestQuantity()
    clearDateRange()
    clearAddOnOptions()
    clearRatePlanChoice()
    clearCouponCode()
    clearCouponSuccess()
    localStorage.removeItem('selectedRooms')
    localStorage.removeItem('roomQuantity')
    localStorage.removeItem('guestQuantity')
    localStorage.removeItem('dateRange')
    localStorage.removeItem('addOnOptions')
    localStorage.removeItem('ratePlanId')
    localStorage.removeItem('coupon')
    localStorage.removeItem('isWheelchairAccessible')
    localStorage.removeItem('termsOfServiceChecked')
  }

  const [createGroupBooking, { loading }] = useMutation(CREATE_GROUP_BOOKING, {
    onCompleted: (createBookingData) => {
      const reservationCode = createBookingData.createGroupBooking?.bookings[0]?.reservationCode
      const originalEmail = userInfo.emailInput
      const resetToken = createBookingData.createGroupBooking?.resetToken
      if(resetToken && originalEmail && reservationCode) {
        removeSavedInformation()
        if(validToken) {
          setReloadTrips (true);
          history.push('/account')
        } else {
          history.push('/checkOutConfirm', { resetToken, originalEmail, reservationCode })
        }
      } else {
        const content = 'Reservation failed!'
        console.log(content)
      }
    },
    onError: (error) => {
      const content = error.message
      console.log(content)
    }
  })
  
  const { isValidatePaymentInfo, paymentError, setPaymentError } = usePaymentForm(creditCardType)
  const [paymentInfo, setPaymentInfo] = useRecoilState(paymentInfoState)

  const setBillingAddress = (address: string) => {
    setPaymentInfo((paymentInfo) => ({...paymentInfo, billingAddress: address}))
  }
  const autocompleteRef = useGoogleAutocomplete(setBillingAddress);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    autocompleteRef
  ]

  const focusNextInput = (currentInputIndex) => {
    const nextInputIndex = currentInputIndex + 1;
    if (nextInputIndex < inputRefs.length) {
      inputRefs[nextInputIndex].current?.focus();
    }
  };
  const onChangeCardHolderName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPaymentInfo((paymentInfo) => ({...paymentInfo, cardHolderName: e.target.value}))
    if(paymentError.cardHolderNameError) {
      setPaymentError((paymentError) => ({...paymentError, cardHolderNameError: false}))
    }
  }

  const onChangeCreditCard = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    setCoverInput(e.target.value)
    setPaymentInfo((paymentInfo) => ({...paymentInfo, creditCardNumber: e.target.value}))
    if(e.target.value.length < 19) {
      setCreditCardType('')
      setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
    } else {
      const type = detectCardType(e.target.value.replace(/\s/g, ''))
      console.log(type)
      if(type.length > 0) {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
        focusNextInput(0)
      } else {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: true}))
      }
      setCreditCardType(type)
    }
  }

  const onChangeCVV = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPaymentInfo((paymentInfo) => ({...paymentInfo, cardCVV: e.target.value}))
    if(paymentError.cardCVVError) {
      setPaymentError((paymentError) => ({...paymentError, cardCVVError: false}))
    } else if(e.target.value.length === 3) {
      focusNextInput(1)
    }
  }

  const onChangeExpireDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPaymentInfo((paymentInfo) => ({...paymentInfo, cardExpirationDate: e.target.value}))
    if(paymentError.cardExpirationDateError) {
      setPaymentError((paymentError) => ({...paymentError, cardExpirationDateError: false}))
    } else if(e.target.value.length === 5) {
      focusNextInput(2)
    }
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserInfo((userInfo) => ({...userInfo, emailInput: e.target.value}))
  }

  const handleCheckOut = () => {
    let result = true

    if (debug) {
      console.log ('Starting checkout');
    }

    if(!termsOfServiceChecked) {
      setTermsOfServiceError(true)
      result = false
      return
    } else {
      if(termsOfServiceError) {
        setTermsOfServiceError(false)
      }
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userInfo.emailInput)) {
      setEmailError(true)
      result = false
    } else {
      if(emailError) {
        setEmailError(false)
      }
    }

    if (!/^[+][0-9]{8,14}$/.test(userInfo.phoneNumberInput)) {
      setPhoneNumberError(true)
      result = false
    } else {
      if(phoneNumberError) {
        setPhoneNumberError(false)
      }
    }
     
    if(result && isValidatePaymentInfo()) {

      const groupInput: GroupTenantBookingInput = {
        fullName: paymentInfo.cardHolderName,
        email: userInfo.emailInput,
        phoneNumber: userInfo.phoneNumberInput,
        checkInDate: new Date(dateRange[0]),
        checkOutDate: new Date(dateRange[1]),
        roomTypeId: persistSelectedRooms.roomTypeId,
        numberOfRooms: roomQuantity,
        numberOfGuests: guestQuantity,
        isWheelchairAccessible: isWheelchairAccessible,
        // Addons removed from API
        //        addons: addOnOptions,
        ratePlanId: ratePlanId,
        billingInfo: {
          cardHolderName: paymentInfo.cardHolderName,
          creditCardNumber: paymentInfo.creditCardNumber.replace(/\s/g, ''),
          cardExpirationDate: paymentInfo.cardExpirationDate.replace(/[\s/]/g, ''),
          cardCVV: paymentInfo.cardCVV,
          billingAddress: paymentInfo.billingAddress,
        },
      }

      if(couponSuccess) {
        groupInput.couponCode = coupon.couponCode
      }

      if (debug) {
        console.log (groupInput);
      }


      createGroupBooking({
        variables: {
          input: groupInput
        }
      })


    } else {
      const content = 'Information is not valid!'
      console.log(content)
    }
  }


  return (
    <CheckOutContentContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <PaymentSectionContainer>
                <PlanSection 
                />
            <div className="paymentDetailsTitle">
              Your payment details:
            </div>
            <PaymentInfoContainer>
              <FormInputField
                label="FULL NAME"
                isRequired={true}
                error={paymentError.cardHolderNameError}
                borderColor={colors.signInBorderColor}
                onChange={(e) => {
                  onChangeCardHolderName(e)
                  if(paymentError.cardHolderNameError) {
                    setPaymentError((paymentError) => ({...paymentError, cardHolderNameError: false}))
                  }
                }}
                onBlur={(e) => {
                  if(e.target.value.length < 3) {
                    setPaymentError((paymentError) => ({...paymentError, cardHolderNameError: true}))
                  } else {
                    setPaymentError((paymentError) => ({...paymentError, cardHolderNameError: false}))
                  }
                }}
                value={paymentInfo.cardHolderName}
                inputHeight="43px"
              />
              <div className="row">
                <FormInputField
                  className='emailInput'
                  label="EMAIL ADDRESS"
                  isRequired={true}
                  error={emailError}
                  errorMessage={errorMessage}
                  type="email"
                  disabled={!!validToken}
                  borderColor={colors.signInBorderColor}
                  onChange={(e) => {
                    onChangeEmail(e)
                    if(emailError) {
                      setEmailError(false)
                    }
                  }}
                  onBlur={(e) => {
                    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                      setEmailError(true)
                      setErrorMessage('Please enter a valid email address and try again.')
                    } else {
                      setEmailError(false)
                    }
                  }}
                  value={userInfo.emailInput}
                  inputHeight="43px"
                />
                <Modal open={openSignInModal} >
                  <SignInModalBody
                    setOpenSignInModal={setOpenSignInModal}
                    emailInput={userInfo.emailInput}
                  />
                </Modal>
                <PhoneNumberInput
                  className='phoneNumberInput'
                  label="PHONE"
                  isRequired={true}
                  labelPosition='left'
                  error={!phoneNumberValidity && phoneNumberError}
                  errorMessage='Please enter a valid phone number'
                  inputHeight="43px"
                  marginBottom={'20px'}
                  onChangeNumber={setPhoneNumber}
                  setPhoneNumberValidity={setPhoneNumberValidity}
                  onBlur={() => {
                    if(!phoneNumberValidity&&(phoneNumber.length<3)) {
                      setPhoneNumberError(true)
                    }
                  }}
                />
              </div>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xl={6} lg={6} sm={12} xs={12}>
                  <ReactInputMask
                    mask={creditCardMask.mask}
                    maskPlaceholder=''
                    onChange={(e) => {
                      onChangeCreditCard(e)
                      if(paymentError.creditCardNumberError) {
                        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
                      }
                    }}
                    onBlur={(e) => {
                      if(e.target.value.length < 19) {
                        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: true}))
                      } else {
                        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
                      }
                    }}
                    value={coverInput}
                  >
                    <FormInputField
                      className='creditCardInput'
                      inputRef={inputRefs[0]}
                      label="CREDIT CARD NUMBER"
                      isRequired={true}
                      placeholder="xxxx xxxx xxxx xxxx"
                      maxLength={19}
                      error={paymentError.creditCardNumberError}
                      errorMessage='Please enter a valid credit card number'
                      borderColor={colors.signInBorderColor}
                      inputHeight="43px"
                      iconUrl={creditCardType? greenCheck: ''}
                    />
                  </ReactInputMask>
                </Grid>
                <Grid
                  item
                  container
                  xl={6}
                  lg={6}
                  sm={11}
                  xs={12}
                  justifyContent="flex-start"
                  spacing={4}
                >
                  <Grid item xl={4} lg={4} sm={4} xs={4}>
                    <ReactInputMask
                      mask={cvvMask.mask}
                      maskPlaceholder=''
                      onChange={(e) => {
                        onChangeCVV(e)
                        if(paymentError.cardCVVError) {
                          setPaymentError((paymentError) => ({...paymentError, cardCVVError: false}))
                        }
                      }}
                      onBlur={(e) => {
                        if(e.target.value.length < 3) {
                          setPaymentError((paymentError) => ({...paymentError, cardCVVError: true}))
                        } else {
                          setPaymentError((paymentError) => ({...paymentError, cardCVVError: false}))
                        }
                      }}
                      value={paymentInfo.cardCVV}>
                      <FormInputField
                        className='cvvInput'
                        inputRef={inputRefs[1]}
                        label="CVV"
                        isRequired={true}
                        placeholder="xxx"
                        error={paymentError.cardCVVError}
                        errorMessage='Please enter a valid CVV'
                        borderColor={colors.signInBorderColor}
                        inputHeight="43px"
                      />
                    </ReactInputMask>
                  </Grid>
                  <Grid item xl={5} lg={5} sm={5} xs={5}>
                    <ReactInputMask
                      mask={expireDateMask.mask}
                      maskPlaceholder=''
                      onChange={(e) => {
                        onChangeExpireDate(e)
                        if(paymentError.cardExpirationDateError) {
                          setPaymentError((paymentError) => ({...paymentError, cardExpirationDateError: false}))
                        }
                      }}
                      onBlur={(e) => {
                        if(e.target.value.length < 5) {
                          setPaymentError((paymentError) => ({...paymentError, cardExpirationDateError: true}))
                        } else {
                          setPaymentError((paymentError) => ({...paymentError, cardExpirationDateError: false}))
                        }
                      }}
                      value={paymentInfo.cardExpirationDate}>
                      <FormInputField
                        className='expireDateInput'
                        inputRef={inputRefs[2]}
                        label="EXPIRY"
                        isRequired={true}
                        placeholder="mm/yy"
                        error={paymentError.cardExpirationDateError}
                        errorMessage='Please enter a valid expiry date'
                        borderColor={colors.signInBorderColor}
                        inputHeight="43px"
                      />
                    </ReactInputMask>
                  </Grid>
                </Grid>
              </Grid>
              <FormInputField
                label="BILLING ADDRESS"
                isRequired={true}
                inputRef={inputRefs[3]}
                error={paymentError.billingAddressError}
                value={paymentInfo.billingAddress}
                borderColor={colors.signInBorderColor}
                onChange={(e) => {
                  setPaymentInfo((paymentInfo) => ({...paymentInfo, billingAddress: e.target.value}))
                  if(paymentError.billingAddressError) {
                    setPaymentError((paymentError) => ({...paymentError, billingAddressError: false}))
                  }
                }}
                onBlur={(e) => {
                  if(e.target.value.length < 3) {
                    setPaymentError((paymentError) => ({...paymentError, billingAddressError: true}))
                  } else {
                    setPaymentError((paymentError) => ({...paymentError, billingAddressError: false}))
                  }
                }}
                inputHeight="43px"
              />
            </PaymentInfoContainer>
          </PaymentSectionContainer>
          <BookingSummary handleCheckOut={handleCheckOut} loading={loading}/>
          <MobileBookingSummary handleCheckOut={handleCheckOut} />
        </div>
      </div>
    </CheckOutContentContainer>
  )
}

export default CheckOutContent;