import styled from 'styled-components';
import { colors } from 'constants/colors';

export const AddOnCardContainer = styled.div<{ selected?: boolean }>`
  min-width: 260px;
    -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    border: 1px solid ${colors.accounBorderColor};
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    flex: 1;
    color: ${colors.commonBlack};


  .optionCardTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 20px;
    color: ${colors.commonBlack};
    margin-bottom: 18px;
  }

  .optionCardAttributes {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 8px;


    .roomAttribute {
      display: flex;
      //margin: 0 2% 0 0;
      align-items: center;
      font-size: 16px;
      gap: 8px;
      /* letter-spacing: -0.5px; */

      svg {
        position: relative;
        width: 12px;
        height: 12px;
      }
    }
  }

  .optionCardPrice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${colors.signInBorderColor};
    padding: 11px 16px;
    margin-top: 8px;
    white-space: nowrap;

    .switch {
      width: 37px;
      min-width: 37px;
      height: 24px;
      border: 2px solid ${colors.profileBorder};
      background-color: ${colors.accounMenuBorderColor};
      border-radius: 14px;
      cursor: pointer;
      
      .switchSlider {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: ${colors.white};
        position: relative;
        top: calc(50% - 8px);
        left: 2px;
        transition: transform 0.3s ease;


        svg {
          position: relative;
          width: 9px;
          height: 9px;
          margin: 3px;
        }
      }

      .switchSlider.active {
        transform: translateX(13px);

        svg {
          top: 0;
          left: 0;
        }
      }
    }

    .switch.active {
      border: 2px solid ${colors.green};
      background-color: ${colors.generalInfoIconBorderNew};
    }

    .price {
      min-width: 50px;
      font-size: 16px;
      font-weight: 600;
      margin: 0 10px;
      color: ${colors.commonBlack};
    }

    .explanation {
      font-size: 16px;
      color: ${colors.userSelectionBlack};
    }


    .optionCardPrice {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.signInBorderColor};
    padding: 11px 16px;
    margin-top: 8px;
    white-space: nowrap;

  }
  .counter {
  display: flex;
  align-items: center;
  gap: 1px;
  margin-right: 15px;
}

.counter-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.2s;
  user-select: none;
}

.counter-value {
  font-size: 18px;
  font-weight: 700;
  min-width: 25px;
  text-align: center;
}

  }
`;