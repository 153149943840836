import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AccountContentContainer } from './style';
import AddOnCard from 'components/AddOnCard';
import Card from 'components/Card';
import StyledButton from 'components/Button';
import { BookingAddOn } from 'states/OptionStates/atoms';
import { useGetBookingAddOns } from 'customHooks/useGetBookingAddOns';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import moment from 'moment';
import Checkbox from 'components/Checkbox'
import { colors } from 'constants/colors';
import { useRecoilState } from 'recoil';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import { useChangeAddons } from 'customHooks/useChangeAddons';
import AlertMessage from 'components/AlertMessage';
import { smoothNavigate } from "components/SmoothNavigate";

import {getAddonPricingRule, getAddonPricingName} from 'constants/addons';
import {MAT_RATE, HST_RATE}  from 'constants/constant';


const AddOnsContent: FC = () => {

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);
  
  
  const closeMessage = () => setMessageState({ isVisible: false, text: '' });
  const closeError = () => setErrorState({ isVisible: false, text: '' });

  const history = useHistory()
  const debug = true;

  const { trips } = useLoadTrips()

  const { handleChangeAddons } = useChangeAddons()

  const bookingAddOns = useGetBookingAddOns()
  const { groupId } = useParams<{groupId: string}>()
  const { bookingId } = useParams<{bookingId: string}>()
  const trip = trips[groupId]
  //const tripAddOns = trip?.addOns?.map((groupSubscribedAddOn) => groupSubscribedAddOn.bookingAddOn.addOnId) ?? []
//   const tripAddOns = trip?.bookings?.flatMap(booking => 
//     booking.addons?.map(addon => addon.bookingAddOn?.id) ?? []
// ) ?? [];

const tripAddOns = trip?.bookings
  ?.filter(booking => booking.bookingId === bookingId) 
  ?.flatMap(booking =>
    booking.addons?.map(addon => ({
      id: addon.bookingAddOn?.id,
      quantity: addon.quantity
    })) ?? []
  ) ?? [];

  
  const currentBooking = trip?.bookings.find(booking => booking.bookingId === bookingId) || null;

  const userId = localStorage.getItem('userId')

  const [tripAddOnsUpdated, setTripAddOnsUpdated] = useState<any[]>([]);
  // addons data updated by user

  const [tripAddOnsInitialAddons, setTripAddOnsInitialAddons] = useState<any[]>([]);
  // addons data that came form server

  const [tripAddOnsAddonsDiff, setTripAddOnsAddonsDiff] = useState<any[]>([]);
  // Diff between addons that came from server and current addons state

  const [addOnsChangedByUser, setAddOnsChangedByUser] = useState(false)

  const [yourNewTotal, setYourNewTotal] = useState(0)

  const [addOnsChanged, setAddOnsChanged] = useState(false)

useEffect(() => {

  if (!addOnsChangedByUser) {
  
  // Get new addons
  const updatedAddOns = [
    ...tripAddOns,
    ...bookingAddOns
      .filter(bookingAddOn => !tripAddOns.some(addon => addon.id === bookingAddOn.addOnId))
      .map(bookingAddOn => ({ id: bookingAddOn.addOnId, quantity: 0 }))
  ];

  
  // Update if it's really new addons 
  if (JSON.stringify(updatedAddOns) !== JSON.stringify(tripAddOnsInitialAddons) ) {
    setTripAddOnsInitialAddons(updatedAddOns);
  }

  if (JSON.stringify(updatedAddOns) !== JSON.stringify(tripAddOnsUpdated) ) {
    setTripAddOnsUpdated(updatedAddOns);
  }
  setAddOnsChangedByUser (false);
}

}, [tripAddOns, bookingAddOns]);


useEffect(() => {
  const diff = tripAddOnsUpdated
    .map(updated => {
      // Find the corresponding initial addon by id
      const initial = tripAddOnsInitialAddons.find(init => init.id === updated.id);
      
      // Skip if there is no change in quantity or if the addon doesn't exist in the initial list
      if (!initial || initial.quantity === updated.quantity) {
        return null;
      }

      return {
        bookingId: updated.bookingId,
        id: updated.id,
        quantity: updated.quantity - initial.quantity, // Calculate the difference
      };
    })
    .filter(Boolean); // Remove null values

  // If there is a difference, set addOnsChanged to true
  setAddOnsChanged(diff.length > 0);
  setTripAddOnsAddonsDiff(diff); // <--- Update state

    
  
  // Room price math
  
  const countTotalRoomsPrice = currentBooking?.roomBookings?.[0] 
    ? (currentBooking.roomBookings[0].pricePerNight ?? 0) * (currentBooking.roomBookings[0].numberOfNights ?? 0)
    : 0;
  
  const countTotalRoomsPriceAfterDiscount = countTotalRoomsPrice - countTotalRoomsPrice * (trip?.ratePlan?.ratePlan?.rateChangeValue ?? 0)/100;
  
  const countTotalRoomsPriceAfterMAT = countTotalRoomsPriceAfterDiscount + countTotalRoomsPriceAfterDiscount * MAT_RATE;


  const countTotalAddonsPriceFromUpdatedAddons = tripAddOnsUpdated.reduce((totalAddonsPrice, addOn) => {

     // Addons math:
  
    const roomNumberOfNights = 
    trip?.bookings
      ?.find(booking => booking.bookingId === bookingId) // Find booking
      ?.roomBookings?.[0]?.numberOfNights ?? 1; // numberOfNights 
  
    
    const addonData = bookingAddOns.find (option => option.addOnId === addOn.id); 
  
    
    const currentAddonpricePerNight  = addonData?.pricePerNight  ?? 0;
 
    const quantity = addOn?.quantity ?? 0;

    const numberOfWhat =
      getAddonPricingRule(addOn.id) === "perItem"
        ? quantity
        : getAddonPricingRule(addOn.id) === "perNight"
          ? roomNumberOfNights 
          : 1;
  
    const totalAddonPrice = 
    getAddonPricingRule(addOn.id) === "perItem"
    ? currentAddonpricePerNight * quantity
    : getAddonPricingRule(addOn.id) === "perNight"
      ? numberOfWhat * currentAddonpricePerNight * quantity
      : currentAddonpricePerNight * quantity;

     return  totalAddonsPrice + totalAddonPrice;

  }, 0);


  const countTotalRoomsPriceAfterHST = countTotalRoomsPriceAfterMAT + countTotalAddonsPriceFromUpdatedAddons + (countTotalRoomsPriceAfterMAT + countTotalAddonsPriceFromUpdatedAddons) * HST_RATE;

  setYourNewTotal (countTotalRoomsPriceAfterHST);

  if (debug) {
    console.log ("trip", trip);
   // console.log("bookingAddOns: ", bookingAddOns);
   // console.log("tripAddOnsInitialAddons: ", tripAddOnsInitialAddons);
   console.log("tripAddOnsUpdated: ", tripAddOnsUpdated);
   // console.log("tripAddOnsAddonsDiff: ", diff);
   console.log("currentBooking: ", currentBooking);
   console.log("countTotalRoomsPrice: ", countTotalRoomsPrice);
   console.log("countTotalRoomsPriceAfterDiscount: ", countTotalRoomsPriceAfterDiscount);
   console.log("countTotalAddonsPriceFromUpdatedAddons: ", countTotalAddonsPriceFromUpdatedAddons);
 }


}, [tripAddOnsUpdated]);
  
  const [addOnOptions, setAddOnOptions] = useState(tripAddOns)



  

  const [applyThisChangeToAllRooms, setApplyThisChangeToAllRooms] = useState(false);
  
  
  const compareArrays = (arr1, arr2)=> {

    const extraInArr1 = arr1.slice();
    const missingInArr1 = arr2.slice();

    arr1.forEach((item, index) => {
        const indexInArr2 = missingInArr1.indexOf(item);
        if (indexInArr2 !== -1) {
          missingInArr1.splice(indexInArr2, 1);
          extraInArr1.splice(index, 1);
        }
    });

    const isEqual = extraInArr1.length === 0 && missingInArr1.length === 0;

    return {
      extraInArr1,
      missingInArr1,
      isEqual
    };
  }

  // const setChecked = (addOnId: string) => {
  //   if (addOnOptions.includes(addOnId)) {
  //     const index = addOnOptions.indexOf(addOnId)
  //     const newAddOnOptions = [...addOnOptions]
  //     newAddOnOptions.splice(index, 1)
  //     if (compareArrays(newAddOnOptions, tripAddOns).isEqual) {
  //       setAddOnsChanged(false)
  //     } else {
  //       setAddOnsChanged(true)
  //     }
  //     setAddOnOptions(newAddOnOptions)
  //   } else {
  //     const newAddOnOptions = [...addOnOptions]
  //     newAddOnOptions.push(addOnId)
  //     setAddOnOptions(newAddOnOptions)
  //     if (compareArrays(newAddOnOptions, tripAddOns).isEqual) {
  //       setAddOnsChanged(false)
  //     } else {  
  //       setAddOnsChanged(true)
  //     }
  //   }
  // }




  const setChecked = (addOnId: string) => {
    setAddOnsChangedByUser(true);
    if (debug) {
      console.log ('Adon ID to change:', addOnId);
    }
    setTripAddOnsUpdated(prevAddOns =>
      prevAddOns.map(addon => {
        console.log('Current addon:', addon); // Выводим состояние перед изменением
        return addon.id === addOnId
          ? { ...addon, quantity: (addon.quantity ?? 0) > 0 ? 0 : 1 }
          : addon;
      })
    );
  };

  // const optionArray = bookingAddOns.map((addOn: BookingAddOn) => {
  //   const isOneTimePriced = addOn.isOneTimePriced;

  //     return (
  //       <AddOnCard
  //         key={addOn.addOnId}
  //         title={addOn.label}
  //         attributes={addOn.description}
  //         isOneTimePriced={addOn.isOneTimePriced}
  //         price={addOn.pricePerNight}
  //         {...(isOneTimePriced
  //           ? {
  //               checked: addOnOptions.includes(addOn.addOnId),
  //               setChecked: () => setChecked(addOn.addOnId),
  //             }
  //           : {
  //               value: towelCount, 
  //               setValue: setTowelCount, 
  //             }
  //         )}
  //       />
        
  //     )
  // })

  const setQuantity = (newQuantity: number, addOnId: string) => {
    setAddOnsChangedByUser (true);
    setTripAddOnsUpdated(prevAddOns =>
      prevAddOns.map(addon =>
        addon.id === addOnId ? { ...addon, quantity: newQuantity } : addon
      )
    );
  };

  const cancelChange = () => {
      setAddOnsChangedByUser (false);
      smoothNavigate(history, `/account/bookings/addOns/${groupId}/${bookingId}`)
  }

  const saveUpdatedAdons = () => {
    const addonsArray = !applyThisChangeToAllRooms
      ? tripAddOnsUpdated
          .filter(addon => !(addon.quantity === 0 && !tripAddOns.some(t => t.id === addon.id)))
          .map(addon => ({
            bookingId,
            id: addon.id,
            quantity: addon.quantity,
          }))
      : trip.bookings.flatMap(booking => {
          if (debug) {
            console.log("booking for addons: ", booking);
          }
          return tripAddOnsUpdated
          .filter(updatedAddon => {
            const existingAddon = booking.addons?.find(addonFromBooking => addonFromBooking.bookingAddOn?.id === updatedAddon.id);
            if (debug) {
              console.log("existingAddon: ", existingAddon);
              console.log("updatedAddon: ", updatedAddon);

            }
            return (existingAddon && existingAddon.quantity !== updatedAddon.quantity) || (!existingAddon && updatedAddon.quantity !== 0);
          })
            .map(addon => ({
              bookingId: booking.bookingId,
              id: addon.id,
              quantity: addon.quantity,
            }));
        });
  
    if (debug) {
      console.log("addonsArray to send =", addonsArray);
    }
  
    handleChangeAddons(addonsArray);
    smoothNavigate(history, `/account/bookings/${groupId}`)
  };

const optionArray = bookingAddOns.map((addOn: BookingAddOn) => {
  const foundOption = tripAddOnsUpdated.find(option => option?.id === addOn.addOnId) || { quantity: 0 };
  const quantity: number = foundOption.quantity ?? 0;
  const oneTimePricedChecked = true ? (quantity > 0): false ;

  if (debug) {
    console.log ("oneTimePricedChecked = ", oneTimePricedChecked);
  }

  return (
    <AddOnCard
      key={addOn.addOnId}
      title={addOn.label}
      attributes={addOn.description}
      isOneTimePriced={addOn.isOneTimePriced}
      price={addOn.pricePerNight}
      {...(addOn.isOneTimePriced
        ? {
            checked: oneTimePricedChecked,
            setChecked: () => setChecked(addOn.addOnId),
          }
        : {
            value: quantity,
            setValue: (newQuantity: number) => setQuantity(newQuantity, addOn.addOnId),
          }
      )}
    />
  );
});



const addonCangesArray = tripAddOnsAddonsDiff.map((addOn) => {

  // Room price math

  const countTotalRoomsPrice = currentBooking?.roomBookings?.[0] 
  ? (currentBooking.roomBookings[0].pricePerNight ?? 0) * (currentBooking.roomBookings[0].numberOfNights ?? 0)
  : 0;

  const countTotalRoomsPriceAfterDiscount = countTotalRoomsPrice - countTotalRoomsPrice * (trip?.ratePlan?.ratePlan?.rateChangeValue ?? 0)/100;

  const countTotalRoomsPriceAfterMAT = countTotalRoomsPriceAfterDiscount + countTotalRoomsPriceAfterDiscount * MAT_RATE;


  if (debug) {
     console.log ("trip", trip);
    // console.log("bookingAddOns: ", bookingAddOns);
    // console.log("tripAddOnsInitialAddons: ", tripAddOnsInitialAddons);
    // console.log("tripAddOnsUpdated: ", tripAddOnsUpdated);
    // console.log("tripAddOnsAddonsDiff: ", diff);
    console.log("currentBooking: ", currentBooking);
    console.log("countTotalRoomsPrice: ", countTotalRoomsPrice);
    console.log("countTotalRoomsPriceAfterDiscount: ", countTotalRoomsPriceAfterDiscount);
  }


  // Addons math:

  const roomNumberOfNights = 
  trip?.bookings
    ?.find(booking => booking.bookingId === bookingId) // Find booking
    ?.roomBookings?.[0]?.numberOfNights ?? 1; // numberOfNights 

  
  const addonData = bookingAddOns.find (option => option.addOnId === addOn.id); 

  
  const currentAddonisOneTimePriced = addonData?.isOneTimePriced ?? false;
  const currentAddonpricePerNight  = addonData?.pricePerNight  ?? 0;
  const currentAddontitle  = addonData?.title  ?? 'Unknown Add-on';

  const addOrRemove  = addOn?.quantity > 0  ? "priceAdd" : 'priceRemove';
  const plusMinus  = addOn?.quantity > 0  ? "+" : '-';
  const addedRemoved  = addOn?.quantity > 0  ? "added" : 'removed';

  const numberOfWhat =
    getAddonPricingRule(addOn.id) === "perItem"
      ? addOn?.quantity
      : getAddonPricingRule(addOn.id) === "perNight"
        ? roomNumberOfNights 
        : 1;

  const quantity = addOn?.quantity ?? 0;

  const totalAddonPrice = 
  getAddonPricingRule(addOn.id) === "perItem"
  ? currentAddonpricePerNight * quantity
  : getAddonPricingRule(addOn.id) === "perNight"
    ? numberOfWhat * currentAddonpricePerNight * quantity
    : currentAddonpricePerNight * quantity;

    const showItemsAndText =
    getAddonPricingRule(addOn.id) === "perNight"
      ? Math.abs(addOn?.quantity ?? 0) + " " + currentAddontitle + "(s)"
      : "";
  

  return (
    <div className="addonsOverviewLine" key={addOn.addOnId}>
    <div className="addonsOverviewTitle addonsOverviewTitleWithPadding">
      <div className="addonNameAndPrice">{currentAddontitle} ({addedRemoved}) <span className={addOrRemove}>{plusMinus}${Math.abs(totalAddonPrice)}</span></div>
      <div className="addonDescription">({Math.abs(numberOfWhat ?? 0)} {getAddonPricingName(addOn.id)}(s) {showItemsAndText} @ ${currentAddonpricePerNight}/{getAddonPricingName(addOn.id)})</div>
    </div>
    <div className="addonsOverviewDots">
      <div className="dashedLine"></div>
    </div>
    <div className="addonsOverviewNumber">$ {totalAddonPrice.toFixed(2)}</div>
  </div>
  );
});

//   const optionArray = bookingAddOns.map((addOn: BookingAddOn) => {
//     return (
//       <AddOnCard
//         key={addOn.addOnId}
//         title={addOn.label}
//         attributes={addOn.description}
//         checked={addOnOptions.includes(addOn.addOnId)}
//         setChecked={() => setChecked(addOn.addOnId)}
//         isOneTimePriced={addOn.isOneTimePriced}
//         price={addOn.pricePerNight}
//       />
      
//     )
// })


  const addedArray = compareArrays(addOnOptions, tripAddOns).extraInArr1.map((addOnId) => {
    const addOn = bookingAddOns.find((addOn) => addOn.addOnId === addOnId)
    return(
      <div key={addOnId} className="addOnChange">
        {addOn?.label + ' (added)'} <span className={'success'}>' +$'</span> {addOn?.pricePerNight+ '/night'}
      </div>
    )
  })

  const removedArray = compareArrays(addOnOptions, tripAddOns).missingInArr1.map((addOnId) => {
    const addOn = bookingAddOns.find((addOn) => addOn.addOnId === addOnId)
    return (
      <div key={addOnId} className="addOnChange">
        {addOn?.label + ' (added)'} <span className={'warning'}>{` -$${addOn?.pricePerNight}`}</span> {addOn?.pricePerNight+ '/night'}
      </div>
    )

  })

  return (
    <AccountContentContainer>
      <AlertMessage
        showMessage={messageState.isVisible}
        //messageType = 'error'
        onClose={() => closeMessage()} // Changing state on close
      >
        {messageState.text}
      </AlertMessage>

      <AlertMessage
        showMessage={errorState.isVisible}
        messageType='error'
        onClose={() => closeError()} // Changing state on close
      >
        {errorState.text}
      </AlertMessage>

      <div className="addonsContainer">
        {optionArray}

      </div>
      {

          addOnsChanged? (
            <>
            <div className="addonsContainerApplyToAll">
                    <Checkbox
            className="accountCheckbox"
            checked={applyThisChangeToAllRooms}
            //error={termsOfServiceError}
            borderColor={colors.labelGrey}
            label="Apply this change to all rooms"
            onChange={() => {
              setApplyThisChangeToAllRooms(!applyThisChangeToAllRooms);   
            }}
            checkboxScale={1.2}
          />
          </div>
          <div
            className="addonsChanges"
          >
        
            <div className="addonsOverview">OVERVIEW</div>
            
            {addonCangesArray}
          <div className="addonsOverviewTotal">
            <div className="addonsOverviewTotalTitle">YOUR NEW TOTAL</div>
            <div className="addonsOverviewDots"></div>
            <div className="addonsOverviewNumber">${yourNewTotal.toFixed(2)}</div>
          </div>

            {/* <div className="cardTitle">DATES</div>
            <div className="description">
              {
                moment(trip.bookings?.reduce((min, current) => {
                  return current.checkInDate < min? current.checkInDate: min;
                }, trip.bookings[0]?.checkInDate)).format('ddd DD MMM') + ' - ' +
                moment(trip.bookings?.reduce((max, current) => {
                  return current.checkOutDate > max? current.checkOutDate: max;
                }, trip.bookings[0]?.checkOutDate)).format('ddd DD MMM')
              }
            </div>
            <div className="cardTitle">Your Add-ons:</div>
            <div className="addOnsChanges">
              {addedArray}
              {removedArray}
            </div> */}
            <div className="buttonContainer">
              <div className="cancelBt"
                onClick={() => {cancelChange ();}}
              >Cancel</div>
              <StyledButton
                width="98px"
                height="48px"
                // margin='32px 0 0 0'
                onClick={() => {
                  saveUpdatedAdons ();
                }}
              >
                Submit
              </StyledButton>
            </div>
          </div>
          </>
        ):null
      }
    </AccountContentContainer>
  );
};

export default AddOnsContent;