/* eslint-disable react/react-in-jsx-scope */
import React, { FC, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import StyledButton from 'components/Button';
import FormInputField from 'components/FormInputField';
import PasswordStrengthBar from 'components/PasswordStrengthBar';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'graphql/service';
import { ResetPasswordScreenContainer } from './style';
import { colors } from 'constants/colors';
import { PasswordToolTip } from 'assets/icons'
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import { useRecoilState } from 'recoil';
import AlertMessage from 'components/AlertMessage';

const ResetForm: FC = () => {

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);

  const history = useHistory()
  const location = useLocation()
  // Get the parameters from the URL
  // const paramsString = location.pathname.split('/reset/')[1]; // This gives us 'email=abc12345131@gmail.com&oneTimeToken=3bfc423330164a839cd3d8f58f4e294a&'

  // // Split the parameters string into individual key-value pairs
  // const paramsArray = paramsString.split('&');
  // // Create an object to hold the parameters
  // const params = {};
  // paramsArray.forEach(param => {
  //     // Split each parameter into key and value
  //     const [key, value] = param.split('=');
  //     if (key && value) {
  //         // Decode and add to the params object
  //         params[key] = decodeURIComponent(value);
  //     }
  // });

  // const token = params['oneTimeToken']

  const params = new URLSearchParams(location.search);
  const token = params.get("otp"); // Получаем значение параметра "otp"
  
  const closeError = () => setErrorState({ isVisible: false, text: '' });
  const closeMessage = () => setMessageState({ isVisible: false, text: '' });

  const [password, setPassword] = useState('')
  const [isHovered, setIsHovered] = useState(false)

  const [resetPassword, { data: resetPasswordData }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted: (resetPasswordData)=> {
        if(resetPasswordData.resetPassword?.userId) {
          console.log('Set password successfully!')
          setMessageState((prevState)=>({
            ...prevState,
            isVisible: true,
            text: "Password successfully changed!"
          }));
          history.push('/account/dashboard')

        } else {
          const content = 'Set password failed!'
          console.log(content)
          setErrorState((prevState)=>({
            ...prevState,
            isVisible: true,
            text: content
          }));
        }
      },
      onError: (error)=> {
        const content = "Password reset error: " + error.message;
        console.log(content)
        setErrorState((prevState)=>({
          ...prevState,
          isVisible: true,
          text: content
        }));
      }
    }
  )

  const handleResetPassword = () => {
    if (password.length>=8) {
      resetPassword({
        variables: {
          input: {
            token,
            newPassword: password
          }
        }
      })
    }
  }

  return (
    <ResetPasswordScreenContainer>
      <div className="titleContainer">

        <div className="innerContainer">
        <AlertMessage
          showMessage={errorState.isVisible}
          messageType='error'
          onClose={() => closeError()} // Changing state on close
        >
          {errorState.text}
        </AlertMessage>
        <AlertMessage
          showMessage={messageState.isVisible}
          messageType='message'
          onClose={() => closeMessage()} // Changing state on close
        >
          {messageState.text}
        </AlertMessage>
          <div className='titleText'>
            <div className="title">
              <div>You’re almost there!</div>
              <div>Pick a  <span className='highLight'>new password</span>.</div>
            </div>
          </div>
          <div className="resetPasswordImage" />
        </div>
      </div>
      <div className="inputRow">
        <div className="innerContainer">
          <div className='titleText'>
            Reset your password
          </div>
          <FormInputField
            label={'PASSWORD'}
            labelColor={colors.grey}
            labelSize={'14px'}
            labelWeight={'600'}
            isRequired
            inputTextColor={colors.black}
            inputFontSize={'14px'}
            inputFontWeight={'600'}
            inputHeight={'43px'}
            inputBackgroundColor={'transparent'}
            marginBottom='8px'
            borderColor={colors.signInBorderColor}
            autoComplete="new-password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
          />
          <PasswordStrengthBar
            className="passwordStrengthBar"
            password={password}
          />
          <div
            className='buttonContainer'
            onMouseEnter={()=>setIsHovered(true)}
            onMouseLeave={()=>setIsHovered(false)}
          >
            <StyledButton
              width="160px"
              height="48px"
              margin='16px 59px 16px 59px'
              disabled={password.length<8}
              onClick={handleResetPassword}
            >
              Reset Password
            </StyledButton>
            {isHovered&&(password.length < 8)? <PasswordToolTip />: null}
          </div>
        </div>
      </div>
    </ResetPasswordScreenContainer>
  )
}

export default ResetForm;