
import { useLoadTrips } from 'customHooks/useLoadTrips';

export const useGetBookingGroupStatus = (groupID) => {

  const { trips, loading, refetch } = useLoadTrips();

  const debug = false;


  let currentGroupBookingStatus = "";

  if (!groupID || loading) {
    currentGroupBookingStatus = '';
    return { currentGroupBookingStatus, loading };
  }


  const group = trips[groupID];

  if (!group) {
    currentGroupBookingStatus = '';
    return { currentGroupBookingStatus, loading };
  }


  if (debug) {
    console.log("group.bookings: ", group);
  }

  // Get the current date (today's date)
  const today = new Date();

  // Initial status:
  currentGroupBookingStatus = "CONFIRMED";

  let checkedOutStatusCounter = 0;
  let expiredStatusCounter = 0;
  let cancelledStatusCounter = 0;
  let incompleteBookings = 0;


  group.bookings.forEach((currentBooking) => {
    switch (currentBooking.status) {
      case "CHECKED_OUT":
        checkedOutStatusCounter++;
        break;
      case "CHECKED_IN":
        // If one booking is CHECKED_IN -> group status is CHECKED_IN
        currentGroupBookingStatus = "CHECKED_IN";
        break;
      case "EXPIRED":
        expiredStatusCounter++;
        break;
      case "CANCELLED":
        cancelledStatusCounter++;
        break;
      case "PENDING":
        if (!(currentBooking.fullName && currentBooking.phoneNumber)) {
          incompleteBookings++;
        }
        break;
    }
  });

  // Determine the final group status
  if (checkedOutStatusCounter === group.bookings.length) {
    currentGroupBookingStatus = "CHECKED_OUT";
  } else if (expiredStatusCounter === group.bookings.length) {
    currentGroupBookingStatus = "EXPIRED";
  } else if (cancelledStatusCounter === group.bookings.length) {
    currentGroupBookingStatus = "CANCELLED";
  } else if (
    cancelledStatusCounter + expiredStatusCounter === group.bookings.length &&
    cancelledStatusCounter > 0 &&
    expiredStatusCounter > 0
  ) {
    currentGroupBookingStatus = "EXPIRED";
  } else if (currentGroupBookingStatus === "CONFIRMED" && incompleteBookings > 0) {
    currentGroupBookingStatus = "INCOMPLETE";
  }

  // Debug log if enabled
  if (debug) {
    console.log(`Group ${groupID} status: ${currentGroupBookingStatus}`);
  }



  return { currentGroupBookingStatus, loading };
};