import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { AccountHeaderContainer } from './style';

const AccountHeader: FC = () => {

  const location = useLocation();
  const { trips } = useLoadTrips();
  let headerTitle = '';
  let shwoHeaderTitle = true;
  let headerTitleAdd = '';
  const debug = false;


  let showProfileLink = false;

  switch (true) {
    case location.pathname==='/account/dashboard':
      headerTitle = 'Dashboard';
      break;
    case location.pathname==='/account/personalDetails':
      headerTitle = 'Personal Details';
      showProfileLink = true;
      break;
    case location.pathname==='/account/email':
      headerTitle = 'Email';
      showProfileLink = true;
      break;  
    case location.pathname==='/account/phoneNumber':
      headerTitle = 'Phone Number';
      showProfileLink = true;
      break;  
    case location.pathname==='/account/bookings':
      headerTitle = 'Bookings';
      break;
      case location.pathname==='/account/CancelGroupBooking':
        headerTitle = 'Cancel Booking';
        break;
    case location.pathname.split('/').length===4:
      
      const bookings = trips[location.pathname.split('/')[3]]?.bookings || [];

      //  RHCFW-255
      // const displayedCodes = bookings.length > 3 
      //   ? [...bookings.slice(0, 3).map(booking => booking.reservationCode), '...'] 
      // : bookings.map(booking => booking.reservationCode);

      // headerTitle = `Booking ${displayedCodes.join(', ')}`;
      
      if (debug) {
        console.log ('trips from header = ', trips);
      }

      if (bookings.length === 1) {
        headerTitle = `Booking ${trips[location.pathname.split('/')[3]]?.primaryBookingCode ?? ''}`;
      } else {
        const primaryBookingCode = trips[location.pathname.split('/')[3]]?.primaryBookingCode;
        headerTitle = primaryBookingCode ? `Group Booking (${primaryBookingCode})` : "Group Booking";
      }
      
      break;
    case location.pathname.startsWith('/account/bookings/addGuestInfo/'):
      headerTitle = 'Add Guest Information';
      break;
      case location.pathname.startsWith('/account/bookings/editGuestInfo/'):
        headerTitle = 'Edit Guest Information';
        break;
    case location.pathname.startsWith('/account/bookings/addOns/'):

      headerTitle = 'Add-ons';

      const bookingId = location.pathname.split('/')[5]; 
      const groupId = location.pathname.split('/')[4]; 
      
      if (debug) {
        console.log ('bookingId = ', bookingId);
        console.log ('groupId = ', groupId);
      }


      const bookingsList = trips[groupId]?.bookings || [];
      const roomIndex = bookingsList.findIndex(booking => booking.bookingId === bookingId);
    
      if (roomIndex !== -1) {
        headerTitleAdd = `: Room ${roomIndex + 1}`;
      }


      break;
    case location.pathname.startsWith('/account/bookings/modifyDates/'):
      headerTitle = 'Modify Dates'

      const bookingIdModifyDates = location.pathname.split('/')[5]; 
      const groupIdModifyDates = location.pathname.split('/')[4]; 
      
      if (debug) {
        console.log ('bookingIdModifyDates = ', bookingIdModifyDates);
        console.log ('groupIdModifyDates = ', groupIdModifyDates);
      }


      const bookingsListModifyDates = trips[groupIdModifyDates]?.bookings || [];
      const roomIndexModifyDates = bookingsListModifyDates.findIndex(booking => booking.bookingId === bookingIdModifyDates);
    
      if (roomIndexModifyDates !== -1) {
        headerTitleAdd = `: Room ${roomIndexModifyDates + 1}`;
      }

      break;   
      case location.pathname.startsWith('/account/bookings/GuestInformation/'):
        headerTitle = 'Guest Information'
        break;
        case location.pathname.startsWith('/account/bookings/CancelGroupBooking/'):
          headerTitle = 'Cancel Booking'
          break; 
     case location.pathname==='/account/billing':
      headerTitle = 'Billing';
      break;  
    case location.pathname==='/account/security':
      headerTitle = 'Security';
      //shwoHeaderTitle = false;
      break;    
    case location.pathname==='/help':
      headerTitle = 'Help';
      break;
    default:
      headerTitle = 'Profile';
  }

  if (debug) {
    console.log ("headerTitleAdd = ", headerTitleAdd);
  }

  return (
    <AccountHeaderContainer>
      {location.pathname==='/account/dashboard'? (
        <>
          <h1>Account</h1>
          {/* <h3>Customize Your Account Settings</h3> */}
        </>
      ): (location.pathname.split('/').length===4 )? (
        <>
          <div className="accountLink">
            <a href='/account'>Account</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <a href='/account/bookings'>Bookings</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <span>{headerTitle}</span>
          </div>
          {shwoHeaderTitle?(<h2>{headerTitle}{headerTitleAdd}</h2>):(null)}
        </>
      ): (location.pathname.split('/').length===5 || location.pathname.startsWith('/account/bookings/addGuestInfo') || location.pathname.startsWith('/account/bookings/addOns') || location.pathname.startsWith('/account/bookings/editGuestInfo') || location.pathname.startsWith('/account/bookings/modifyDates/')) ? (
        <>
          <div className="accountLink">
            <a href='/account'>Account</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <a href='/account/bookings'>Bookings</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <a href={`/account/bookings/${location.pathname.split('/')[4]}`}>
              Booking {trips[location.pathname.split('/')[4]]?.bookings[0]?.reservationCode}
            </a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
            <span>{headerTitle}</span>
          </div>
          {shwoHeaderTitle?(<h2>{headerTitle}{headerTitleAdd}</h2>):(null)}
        </>
      ):(
        <>
          <div className="accountLink">
            <a href='/account'>Account</a>
            <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
                  {showProfileLink && (
                    <>
                      <a href="/account/profile">Profile</a>
                      <span>{'\u00A0\u00A0\u00A0>\u00A0\u00A0\u00A0'}</span>
                    </>
                  )}
            <span>{headerTitle}</span>
          </div>
          {shwoHeaderTitle?(<h2>{headerTitle}{headerTitleAdd}</h2>):(null)}
        </>
      )}
    </AccountHeaderContainer>
  );
};

export default AccountHeader;
