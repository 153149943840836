import { FC } from 'react';
import { useParams, useLocation} from 'react-router-dom';
import { useState, useEffect   } from 'react';
import { AddGuestInfoContainer } from './style';

import PhoneNumberInput from 'components/PhoneNumberInput';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import StyledButton from 'components/Button';
import NumberInputWithButtons from "components/NumberInputWithButtons";
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { useSetRecoilState, useRecoilState } from 'recoil';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import {useChangeGuestInfo} from 'customHooks/useChangeGuestInfo';
import { useHistory } from 'react-router-dom'
import AlertMessage from 'components/AlertMessage';
import { reloadTrips } from  'states/CommonStates/atoms';


const AddGuestInfoContent: FC = () => {


    // Add or Edit guests...
    const location = useLocation();
    const isEditMode = location.pathname.includes('editGuestInfo');
    const setReloadTrips = useSetRecoilState(reloadTrips);
    
  const debug = true;

  const { handleChangeGuestInfo } = useChangeGuestInfo(() => {
    // Refething data on success information change by callback
    //refetch();
    setReloadTrips(true);
    goToNextRoom ();
  });

  const history = useHistory()

  const { trips, loading, refetch } = useLoadTrips()

  const { groupId, roomId } = useParams<{groupId: string, roomId:string}>()

  const roomIdNumber = Number(roomId);

  const [phoneNumber, setPhoneNumber] = useState('');

  const [numberOfKeysValue, setNumberOfKeysValue] = useState(1);



  const [newPhoneNumberErrorMessage, setNewPhoneNumberErrorMessage] = useState(false);
  const [newPhoneNumberErrorMessageText, setNewPhoneNumberErrorMessageText] = useState('Please enter valid phone number.');

  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [numberOfRooms, setNumberOfRooms] = useState(0);

  const [roomNumber, setRoomNumber] = useState(roomIdNumber);

  const [errorMessageFullName, setErrorMessageFullName] = useState(false);
  const [errorMessageFullNameText, setErrorMessageFullNameText] = useState('Please enter correct Full name.');
  const [fullNameValue, setFullNameValue] = useState('');

  const [roomType, setRoomType] = useState('');
  const [roomFeatures, setRoomFeatures] = useState('');

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);

  const closeMessage = () => setMessageState({ isVisible: false, text: '' });
  const closeError = () => setErrorState({ isVisible: false, text: '' });

  useEffect(() => {
    if (debug) {
      console.log('Trips from add gues information: ', trips);
    }
    setNumberOfRooms(trips[groupId]?.bookings?.length);
    setPhoneNumber(trips[groupId]?.bookings[roomNumber - 1]?.phoneNumber || '');
    setFullNameValue(trips[groupId]?.bookings[roomNumber - 1]?.fullName || '');

    setRoomType(trips[groupId]?.bookings[roomNumber - 1]?.roomBookings[0].room?.roomType?.name || '');
    setRoomFeatures(trips[groupId]?.bookings[roomNumber - 1]?.roomBookings[0].room?.roomType?.bed || '');

    setNumberOfKeysValue(trips[groupId]?.bookings[roomNumber - 1]?.numberOfRoomKeys || 1);

  }, [trips]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);


  useEffect(() => {
    if (debug) {
      console.log('Moving to next guest...');
    }
    setPhoneNumber(trips[groupId]?.bookings[roomNumber - 1]?.phoneNumber || '');
    setFullNameValue(trips[groupId]?.bookings[roomNumber - 1]?.fullName || '');

    setRoomType(trips[groupId]?.bookings[roomNumber - 1]?.roomBookings[0].room?.roomType?.name || '');
    setRoomFeatures(trips[groupId]?.bookings[roomNumber - 1]?.roomBookings[0].room?.roomType?.bed || '');

  }, [roomNumber]);



  if (debug) {
    console.log ("groupId from add gues information: ", groupId);
    console.log ("roomId from add gues information: ", roomId);
  }


  const goToNextRoom = () => {

    if (isEditMode) {
      window.history.pushState(
        null,
        '',
        `/account/bookings/addGuestInfo/${groupId}/${roomNumber}`
      );
      history.push(`/account/bookings/GuestInformation/${groupId}`);
    }
    else {

      if (roomNumber < trips[groupId]?.bookings.length) {
        //FIXME: Need to add back next buttons functionallity
          window.history.pushState(
            null,
            '',
            `/account/bookings/addGuestInfo/${groupId}/${roomNumber}`
          );
          setRoomNumber((prevRoomNumber) => (prevRoomNumber + 1));
        }
        else {
          window.history.pushState(
            null,
            '',
            `/account/bookings/addGuestInfo/${groupId}/${roomNumber}`
          );
          history.push(`/account/bookings/GuestInformation/${groupId}`);
        }

    }

  }

  
  const addNextGuest = () => {

    let noErrors = true;

    if (fullNameValue.length < 2) {
      setErrorMessageFullNameText("Please, enter correct Full name.");
      setErrorMessageFullName(true);
      noErrors = false;
    }

    if (!/^[+][0-9]{8,14}$/.test(newPhoneNumber)) {
      setNewPhoneNumberErrorMessage(true);
      noErrors = false;
      if (debug) {
        console.log('Phone number is incorrect...');
      }
    } else {
      if (debug) {
        console.log('Phone number is correct!');
      }
    }


    if (noErrors) {
      if (newPhoneNumber !== trips[groupId]?.bookings[roomNumber - 1]?.phoneNumber ||
        fullNameValue !== trips[groupId]?.bookings[roomNumber - 1]?.fullName ||
        numberOfKeysValue !== trips[groupId]?.bookings[roomNumber - 1]?.numberOfRoomKeys
      )
        {
          if (debug) {
            console.log ("Sending data booking Id:", trips[groupId]?.bookings[roomNumber - 1]?.bookingId, "numberOfKeysValue: ", numberOfKeysValue, "fullNameValue: ", fullNameValue, "phoneNumber: ", newPhoneNumber);
          }
          handleChangeGuestInfo(trips[groupId]?.bookings[roomNumber - 1]?.bookingId, numberOfKeysValue, fullNameValue, newPhoneNumber);
          
        }
      else {
        if (debug) {
          console.log ("Data not changed... moving to next room");
        }
        goToNextRoom ();
      }
    }
  }

  if (isNaN(roomIdNumber)) {
    return <div>Error: Invalid roomId</div>;
  }

  if (loading || !trips) {
    return (
      <AddGuestInfoContainer>
      <div className="accountBody">
        {/* <div className="loading">Loading...</div> */}
      </div>
      </AddGuestInfoContainer>
    );
  }

  return (
    <AddGuestInfoContainer>

            <AlertMessage
                showMessage={messageState.isVisible}
                //messageType = 'error'
                onClose={() => closeMessage()} // Changing state on close
            >
                {messageState.text}
            </AlertMessage>
            
            <AlertMessage
                showMessage={errorState.isVisible}
                messageType = 'error'
                onClose={() => closeError()} // Changing state on close
            >
                {errorState.text}
            </AlertMessage>

      <div className="accountBody">
        <div className="cardContent">
          <h2 className="room">Room {roomNumber}/{numberOfRooms}</h2>
          <div className="roomOptions">
            <div className="roomType">
              <div className="optionTitle">ROOM TYPE</div>
              <div className="optionContent">{roomType}&nbsp;</div>
            </div>
            <div className="roomFeatures">
              <div className="optionTitle">ROOM FEATURES</div>
              <div className="optionContent">{roomFeatures}&nbsp;</div>
            </div>
          </div>
          <div className="roomForm">
            <div className="formBody">
              <div className="phoneCel">
              <PhoneNumberInput
                    key={`${roomNumber}-${phoneNumber || "empty"}`} // refresh initialValue once phone changed
                    initialValue={phoneNumber}
                    className='phoneNumberInput'
                    label="PHONE"
                    isRequired={true}
                    labelPosition='left'
                    error={newPhoneNumberErrorMessage}
                    errorMessage={newPhoneNumberErrorMessageText}
                    inputHeight="49px"
                    marginBottom={'20px'}
                    onChangeNumber={(newNumber) => {
                      if (debug) {
                        console.log ("newNumber=", newNumber);
                      }
                      setNewPhoneNumber(newNumber);
                      if (newNumber!==newPhoneNumber) {
                        setNewPhoneNumberErrorMessage(false);
                      }
                      
                    }}
                  />                
              </div>
              <div className="nameCel">
                <FormInputField
                  className="secInput"
                  // highLightLabel='CURRENT '
                  label="FULL NAME"
                  isRequired
                  type="text"
                  borderColor={colors.signInBorderColor}
                  error={errorMessageFullName}
                  errorMessage={errorMessageFullNameText}
                  // value={oldPassword}
                  inputHeight="48px"
                  marginBottom="20px"
                  value={fullNameValue}
                  maxLength={300}
                  onChange={(e) => {
                    setErrorMessageFullName(false);
                    setFullNameValue(e.target.value);
                  }}
                />
              </div>
              <div className="numberOfKeysCel">
              <NumberInputWithButtons
                min={1} 
                max={2}
                value={numberOfKeysValue}
                onChange={setNumberOfKeysValue}
                errorMessage={undefined}
              />
              </div>
            </div>
            <div className="formControls">
              <StyledButton
                width={isEditMode ? "180px" : roomNumber === numberOfRooms ? "110px" : "161px"}
                height="48px"
                disabledBackgroundColor={colors.signInBorderColor}
                disabledColor={colors.white}
                onClick={() => {
                  addNextGuest();
                }}
              >
                {isEditMode ? "Update Information" : roomNumber === numberOfRooms ? "Continue" : "Add Next Guest"}
              </StyledButton>
            </div>
          </div>

        </div>
      </div>
    </AddGuestInfoContainer>
  );
};

export default AddGuestInfoContent;
