import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AccountContentContainer } from './style';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { ADVANCED, ADVANCED_RATE } from 'constants/ratePlanId';
import { HST_RATE, MAT_RATE } from 'constants/constant';
import moment from 'moment';
import {  DownloadRecept, EditNewIcon, Bin, Magic } from 'assets/icons';
import { Icon } from 'components/Icon';
import { Modify } from 'ol/interaction';
import ModifyDatesContent from '../ModifyDates';
import { useRecoilState } from 'recoil';
import { cancelBookingModalOpenState, cancelReasonModalOpenState, cancelGroupId, cancelBookingId, canceledGroupIdState, cancelBookingFee, canceledBookingIdState } from 'states/ModalStates/atoms';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';


import Modal from 'components/Modal';
import {bookingsStatuses} from 'constants/constant';

import AlertMessage from 'components/AlertMessage';
import BookingBannerCompleteYourBooking from 'components/BookingBannerCompleteYourBooking';
import CancelBookingModalBody from '../CancelBookingModalBody';
import CancelReasonModalBody from '../CancelReasonModalBody';

import { useGetBookingGroupStatus } from 'customHooks/useGetBookingGroupStatus';
import { colors } from 'constants/colors';

import GuestInformation  from '../GuestInformation';
import { smoothNavigate } from "components/SmoothNavigate";


const debug = false;

const BookingDetailsContent: FC = () => {

  const [openCancelBookingModal, setOpenCancelBookingModal] = useRecoilState(cancelBookingModalOpenState)
  const [openCancelReasonModal, setOpenCancelReasonModal] = useRecoilState(cancelReasonModalOpenState)
  
  const [cancelModalGroupId, setCancelModalGroupId] = useRecoilState(cancelGroupId)
  const [cancelModalBookingId, setCancelModalBookingId] = useRecoilState(cancelBookingId)
  const [cancelModalBookingFee, setCancelModalBookingFee] = useRecoilState(cancelBookingFee)

  const [isGroupBookingCancelled, setIsGroupBookingCancelled] = useState(false);
  const [isGroupBookingIncomplite, setIsGroupBookingIncomplite] = useState(false);
  const [isGroupBookingCheckedOut, setIsGroupBookingChackedOut] = useState(false);
  const [isGroupBookingCheckedIn, setIsGroupBookingChackedIn] = useState(false);

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);
  
  
  const closeMessage = () => setMessageState({ isVisible: false, text: '' });
  const closeError = () => setErrorState({ isVisible: false, text: '' });


  const history = useHistory()
  const { trips, loading, refetch } = useLoadTrips()
  const { groupId } = useParams<{groupId: string}>()
  const trip = trips[groupId]

  const [canceledGroupId, setCanceledGroupId] = useRecoilState(canceledGroupIdState);


  const { currentGroupBookingStatus} =  useGetBookingGroupStatus(groupId);

  // useEffect(() => {
  //   if (canceledGroupId) {
  //     history.push(`/account/bookings`)
  //   }
  // }, [canceledGroupId]);

  useEffect(() => {

      if (debug) {
        console.log ("currentGroupBookingStatus: ", currentGroupBookingStatus);
      }

      if (currentGroupBookingStatus === "CANCELLED") {
        setIsGroupBookingCancelled (true);
      }
      else {
        setIsGroupBookingCancelled (false);
      }

      if (currentGroupBookingStatus === "INCOMPLETE") {
        setIsGroupBookingIncomplite (true);
      }
      else {
        setIsGroupBookingIncomplite (false);
      }

      if (currentGroupBookingStatus === "CHECKED_OUT") {
        setIsGroupBookingChackedOut (true);
      }
      else {
        setIsGroupBookingChackedOut (false);
      }

      if (currentGroupBookingStatus === "CHECKED_IN") {
        setIsGroupBookingChackedIn (true);
      }
      else {
        setIsGroupBookingChackedIn (false);
      }

  }, [currentGroupBookingStatus]);


  if (debug) {
    console.log ("trip =", trip);
  }

  // Old code with trips
  if (loading || !trip) {
    return (
      <AccountContentContainer>
      <div className="accountBody">
        {/* <div className="loading">Loading...</div> */}
      </div>
      </AccountContentContainer>
    );
  }





  /* Consts for output: */

   // Old code with trips
  const trip_addons = trip.addOns?.map((groupSubscribedAddOn) =>
    groupSubscribedAddOn.bookingAddOn.label
  )
    .join(", ");


// Booking addins

    // const booking_addons = bookingsLoaded.addons
    // ? bookingsLoaded.addons
    //   .map(addon => addon.bookingAddOn?.title || '')
    //   .filter(title => title) // 
    //   .join(', ')
    // : '';

  // Old code with trips
    const is_group_booking = trip.bookings.length > 1 ? true : false;
  
  
    // New code with bookings
  //const is_group_booking = bookingsLoaded.length > 1 ? true : false;
  

  const trip_number_of_rooms = trip.numberOfRooms;

//  const trip_number_of_guests = trip.numberOfGuests;


// Vadim: is there may be any other option for rateChangeType than decrease_by_percent?
// Emmy: decrease_by_percent , increase_by_percent, decrease_by_amount , increase_by_amount


  const trip_rateplan = trip.ratePlan?.ratePlan?.title;

    //const trip_number_of_nights = trip.bookings[0]?.roomBookings[0]?.numberOfNights;

    const trip_total_number_of_nights = trip.bookings.reduce((total, booking) => {
      const number_of_nights_in_booking = booking.roomBookings.reduce((sum, room_booking) => {
        return sum + (room_booking.numberOfNights ?? 0); // undefined check
      }, 0);
    
      return total + number_of_nights_in_booking;
    }, 0);

    const trip_bills_total = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.total ?? 0); // undefined check
    }, 0);

    const trip_hst = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.HST ?? 0); // undefined check
    }, 0);

    const trip_mat = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.MAT ?? 0); // undefined check
    }, 0);

    const bookings = trip.bookings;

    const ModifyRoomDates = (booking_id: string) => {
      if (debug) {
        console.log('booking_id = ' + booking_id);
      }
      smoothNavigate(history, `/account/bookings/modifyDates/${groupId}/${booking_id}`)
      //history.push(`/account/bookings/modifyDates/${groupId}/${booking_id}`);
    };

    const compliteBooking = () => {
      smoothNavigate(history, `/account/bookings/addGuestInfo/${groupId}/1`)
    };

    const ModifyRoomAddons = (booking_id: string) => {
      if (debug) {
        console.log('booking_id = ' + booking_id);
      }
      smoothNavigate(history, `/account/bookings/addOns/${groupId}/${booking_id}`)
      //history.push(`/account/bookings/addOns/${groupId}/${booking_id}`);
    };

    const CancelBooking = () => {
      if (is_group_booking) {
        smoothNavigate(history, `/account/bookings/CancelGroupBooking/${groupId}`)
      }
      else {
        setCancelModalGroupId(groupId)
        setCancelModalBookingId(trips[groupId].bookings[0]?.bookingId)
        setOpenCancelBookingModal(true)
      }
    };


    const CancelRoom = (bookingId) => {
        setCancelModalBookingId(bookingId)
        setCancelModalGroupId ('');
        setOpenCancelBookingModal(true)
    };



    const totalRooms =  Object.keys(bookings).length;

    let showRoomNumber = false;
    let cancelBookingText = 'Cancel Booking';

    if (totalRooms > 1) {
        showRoomNumber = true;
        cancelBookingText = 'Cancel Entire Booking';

    }

    if (debug) {
      console.log ('totalRooms = ' + totalRooms);
    }

    const roomsArray = Object.keys(bookings).map((index) => {
      
      const checkInDate = moment (bookings[index].checkInDate).format("ddd DD MMM h:mm A");
      const checkOutDate = moment (bookings[index].checkOutDate).format("ddd DD MMM h:mm A");

      // for group booking


      // Booking addins

    // const booking_addons = bookingsLoaded.addons
    // ? bookingsLoaded.addons
    //   .map(addon => addon.bookingAddOn?.title || '')
    //   .filter(title => title) // 
    //   .join(', ')
    // : '';


      let rooomNumber = +index + 1;

      const trip_checkin = moment(
        bookings?.reduce((min, current) => {
          return current.checkInDate < min ? current.checkInDate : min;
        }, bookings[index]?.checkInDate)
      ).format("ddd, DD MMM"); // old format was format("ddd DD MMM, h:mm A")
    
      const trip_checkout = moment(
        bookings?.reduce((max, current) => {
            return current.checkOutDate > max
              ? current.checkOutDate
              : max;
          }, bookings[index]?.checkOutDate)
        ).format("ddd, DD MMM"); // old format was format("ddd DD MMM, h:mm A")
    

      const trip_confirmation_code = bookings[index]?.reservationCode;
      
      //const trip_number_of_guests = bookingsLoaded[index].tenantGroup.tenant.user;

      const trip_number_of_guests = 1;

      //console.log(trip_number_of_guests);

      let booking_status = bookings[index]?.status;
      let allow_booking_modification = false;

      let booking_rateplan = trip.ratePlan?.ratePlan?.title;

      let booking_room_type = bookings[index]?.roomBookings[0].room.roomType.name + " - " + bookings[index]?.roomBookings[0].room.roomType.bed + " " + (bookings[index]?.roomBookings[0].room.roomType.hasWindows ? "w/ window" : "w/ skylight");

      if (debug) {
        console.log ("rate plan:", booking_rateplan);
      }
      
      let booking_rateplan_off = '';

      const rateChangeValue = trip.ratePlan?.ratePlan?.rateChangeValue ?? 0;
      
      if (rateChangeValue > 0) {
         booking_rateplan_off = `(${rateChangeValue}% off)`;
      }

      if (booking_status === 'PENDING') {

        allow_booking_modification = true;

        if (!(bookings[index]?.fullName && bookings[index]?.phoneNumber)) {
          booking_status = 'INCOMPLETE';
        }
        else {
          booking_status = 'CONFIRMED';
        }

      }

      let allow_remove = false;
      
      if ((booking_status !== "CHECKED_OUT") && (booking_status !== "CANCELLED") && (booking_status !== "EXPIRED") && (booking_status !== "CHECKED_IN") && (bookings.length > 1)) {
        allow_remove = true;
      }


      if (debug) {
        console.log ("Booking ID for room cancel: ", bookings[index].bookingId);
      }

      const booking_addons = bookings[index].addons
        ? bookings[index].addons
          .map(addon => addon.bookingAddOn?.title || '')
          .filter(title => title) // 
          .join(', ')
        : '';
      

      return (
        <div className="room" key={rooomNumber}>
            
          {showRoomNumber && <div className="room">Room {rooomNumber}</div>}

        <div className="detailsHeader">
            STATUS:{" "}
            <span className={bookingsStatuses[booking_status]?.className || 'checkedIn'}>
              {bookingsStatuses[booking_status]?.text?.toUpperCase()}
            </span>
            {allow_remove && (
              <div className="accountLink accountLinkRed">
                <Icon width={11} height={13}>
                  <Bin />
                </Icon>
                <div
                  className="link"
                  onClick={() => CancelRoom(bookings[index].bookingId)}
                >
                  Remove Room
                </div>
              </div>
            )}
          </div>
          <div className="detailsLarge">
          ROOM TYPE{" "}
            <span>
              {booking_room_type}
            </span>


            <div className={`accountLink ${!allow_booking_modification ? "disabled" : ""}`}>
            <Icon width={14} height={14}>
                <EditNewIcon
                  style={{
                    ...(!allow_booking_modification ? { fill: colors.helpBoxBorder, stroke: colors.helpBoxBorder } : {}),
                    cursor: allow_booking_modification ? "pointer" : "not-allowed"
                  }}
                />
              </Icon>
              <div
                className="link"
                onClick={allow_booking_modification ? () => ModifyRoomDates(trip_confirmation_code) : undefined}
                style={{
                  color: allow_booking_modification ? colors.hoverBlue : colors.helpBoxBorder,
                  cursor: allow_booking_modification ? "pointer" : "not-allowed",
                }}
              >
                Modify Room
              </div>
            </div>

          </div>
          <div className="detailsLarge">
            CHECK-IN/CHECK-OUT{" "}
            <span>
              {trip_checkin} - {trip_checkout}
            </span>

            <div className={`accountLink ${!allow_booking_modification ? "disabled" : ""}`}>
            <Icon width={14} height={14}>
                <EditNewIcon
                  style={{
                    ...(!allow_booking_modification ? { fill: colors.helpBoxBorder, stroke: colors.helpBoxBorder } : {}),
                    cursor: allow_booking_modification ? "pointer" : "not-allowed"
                  }}
                />
              </Icon>
              <div
                className="link"
                onClick={allow_booking_modification ? () => ModifyRoomDates(bookings[index].bookingId) : undefined}
                style={{
                  color: allow_booking_modification ? colors.hoverBlue : colors.helpBoxBorder,
                  cursor: allow_booking_modification ? "pointer" : "not-allowed",
                }}
              >
                Modify Dates
              </div>
            </div>

          </div>
          <div className="detailsLarge">
            CONFIRMATION <span>{trip_confirmation_code}</span>
          </div>
          <div className="detailsLarge">
            EXTRA <span>{booking_addons} </span>

            <div className={`accountLink ${!allow_booking_modification ? "disabled" : ""}`}>
            <Icon width={14} height={14}>
                <EditNewIcon
                  style={{
                    ...(!allow_booking_modification ? { fill: colors.helpBoxBorder, stroke: colors.helpBoxBorder } : {}),
                    cursor: allow_booking_modification ? "pointer" : "not-allowed"
                  }}
                />
              </Icon>
              <div
                className="link"
                onClick={allow_booking_modification ? () => ModifyRoomAddons(bookings[index].bookingId) : undefined}
                style={{
                  color: allow_booking_modification ? colors.hoverBlue : colors.helpBoxBorder,
                  cursor: allow_booking_modification ? "pointer" : "not-allowed",
                }}
              >
                Modify Addons
              </div>



            </div>
          </div>
          <div className="detailsLarge">
            RATE{" "}
            <span>
              {booking_rateplan} {booking_rateplan_off}
            </span>
          </div>
        </div>
      );
    })


    if (debug) {
      console.log(trip);
      //console.log (trips);

      console.log (is_group_booking);
    }
  

  return (
    <AccountContentContainer>
      <AlertMessage
        showMessage={messageState.isVisible}
        //messageType = 'error'
        onClose={() => closeMessage()} // Changing state on close
      >
        {messageState.text}
      </AlertMessage>

      <AlertMessage
        showMessage={errorState.isVisible}
        messageType='error'
        onClose={() => closeError()} // Changing state on close
      >
        {errorState.text}
      </AlertMessage>
            
            <BookingBannerCompleteYourBooking 
              groupID={groupId}
            />

      <div className="accountBody">
        <div className="cardContent">
        <div className="topRtLinks">
        {isGroupBookingIncomplite && (
            <div className="compliteBooking" onClick={compliteBooking}>
                <Icon width={16} height={16}>
                  <Magic />
                </Icon>
                <div className="compliteBookingLink">
                  Complete Your Booking
                </div>
            </div>
        )}

        {!isGroupBookingCancelled && !isGroupBookingCheckedOut && !isGroupBookingCheckedIn && (
            <div className="cancelBooking" onClick={CancelBooking}>
              {cancelBookingText}
            </div>
        )}
        </div>


          {roomsArray}
          <div className="detailsLarge breakDown">BREAKDOWN</div>
          
          {isGroupBookingCancelled && 
          <div className="breakDownLine breakDownLineRed">
          <div className="breakDownTitle">Cancellation fee</div>
          <div className="breakDownDots">
            <div className="dashedLineRed"></div>
          </div>
          <div className="breakDownNumber">-$XX.XX</div>
          </div>
          }

          

          {/* <div className="breakDownLine">
            <div className="breakDownTitle">
              {trip_number_of_rooms} Room - {trip_total_number_of_nights} Nights
            </div>
            <div className="breakDownDots"></div>
            <div className="breakDownNumber"></div>
          </div> */}


{!isGroupBookingCancelled && 

  <div>
          <div className="breakDownLine">
            <div className="breakDownTitle breakDownTitleWithPadding">
            Rook - Queen bed w/ skylight (Apr/16/2024)
            </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>

          <div className="breakDownLine">
            <div className="breakDownTitle breakDownTitleWithPadding">
            King - King bed w/ window (Apr/16/2024) 
            </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>

          <div className="breakDownLine">
            <div className="breakDownTitle breakDownTitleWithPadding">
            Rook - Queen bed w/ skylight (Apr/17/2024) 
            </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>

          <div className="breakDownLine">
            <div className="breakDownTitle breakDownTitleWithPadding">
            King - King bed w/ window (Dec/17/2024)
            </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>


          <div className="breakDownLine breakDownLineRed">
            <div className="breakDownTitle">Advanced Purchase (10% off)</div>
            <div className="breakDownDots">
              <div className="dashedLineRed"></div>
            </div>
            <div className="breakDownNumber">-${+(trip_bills_total / 10).toFixed(2)}</div>
          </div>


          <div className="breakDownLine breakDownLineRed">
            <div className="breakDownTitle">Coupon Code (ChristmasPromoDec2024)</div>
            <div className="breakDownDots">
              <div className="dashedLineRed"></div>
            </div>
            <div className="breakDownNumber">-$???.??</div>
          </div>


          <div className="breakDownLine">
            <div className="breakDownTitle">Municipal Accommodation Tax (6%)</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">${trip_mat}</div>
          </div>


          <div className="breakDownLine">
            <div className="breakDownTitle">Parking  (2 nights, 2 cars @ $10/night) </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>


          <div className="breakDownLine">
            <div className="breakDownTitle">Breakfast (4 vouchers @ $15/meal)</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>


          <div className="breakDownLine">
            <div className="breakDownTitle">Late Checkout (2 rooms @ $25/room) </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
      </div>
}


          <div className="breakDownLine">
            <div className="breakDownTitle">HST (13%)</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">${trip_hst}</div>
          </div>
          <div className="breakDownTotal">
            <div className="breakDownTitle">TOTAL</div>
            <div className="breakDownDots"></div>
            <div className="breakDownNumber">${trip_bills_total}</div>
          </div>
          <div className="downloadReceipt">
            <div className="accountLink">
              <Icon width={17} height={18}>
                <DownloadRecept />
              </Icon>
              <div className="link">Download Receipt - ???</div>
            </div>
          </div>
        </div>
        {is_group_booking && !isGroupBookingCancelled ? (
          <GuestInformation 
            showDone = {false}
            processAlerts = {false}
          />
        ) : null}

      </div>
        <Modal open={openCancelBookingModal} >
          <CancelBookingModalBody />
        </Modal>
        <Modal open={openCancelReasonModal} >
          <CancelReasonModalBody />
        </Modal>
      {/* <Card padding="32px">
        <div className="cardTitle">
          <span>Status: </span>
          <span className="highLight">{trip?.status}</span>
        </div>
        <div className="cardContent">
          <div className="breakDownRow">
            <div className="breakDownLabel">CHECK-IN/CHECK-OUT</div>
            <div className="breakDownValue">
              {moment(
                trips[groupId].bookings?.reduce((min, current) => {
                  return current.checkInDate < min ? current.checkInDate : min;
                }, trips[groupId].bookings[0]?.checkInDate)
              ).format("ddd DD MMM, h:mm A") +
                "-" +
                moment(
                  trips[groupId].bookings?.reduce((max, current) => {
                    return current.checkOutDate > max
                      ? current.checkOutDate
                      : max;
                  }, trips[groupId].bookings[0]?.checkOutDate)
                ).format("ddd DD MMM, h:mm A")}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">CONFIRMATION</div>
            <div className="breakDownValue">
              ${trip.bookings[0]?.reservationCode}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">ROOMS / GUESTS</div>
            <div className="breakDownValue">
              {trip.numberOfRooms + "/" + trip.numberOfGuests}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">EXTRA</div>
            <div className="breakDownValue">
              {trip.addOns
                ?.map(
                  (groupSubscribedAddOn) =>
                    groupSubscribedAddOn.bookingAddOn.label
                )
                .join(", ")}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">RATE</div>
            <div className="breakDownValue">
              ${trip.ratePlan?.ratePlan?.title}
            </div>
          </div>
          <div className="breakDown">
            <div className="breakDownTitle">BREAKDOWN</div> */}

      {/* <div className="breakDownRow">
              <div className="breakDownLabel">
                {((trip.numberOfRooms ?? 0) > 1 ? (trip.numberOfRooms ?? 0) + ' Rooms' : (trip.numberOfRooms ?? 0) + ' Room')
                  + ' - '  + (nights>1? nights + ' Nights ': nights + ' Night ')
                }
                <br />
                {'\u00a0\u00a0\u00a0\u00a0' + (.bed || 'Queen bed') + ' w/'
                  + (persistSelectedRooms.hasWindows? ' Windows': ' skylight')
                }
              </div>
              <div className="breakDownValue">
                ${roomFee.toFixed(2)}
              </div>
            </div> */}
      {/* {couponSuccess && (
              <div className="breakDownRow">
                <div className="breakDownLabel">
                  <span className="warning">{`Coupon (${trip.coupon.rateType==='FIXED_RATE'? '$'+ trip.coupon.discount + ' off': trip.coupon.discount + '% off'})`}</span>
                </div>
                <div className="breakDownValue">
                  <span className="warning">-${couponDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}
            {ratePlanId===ADVANCED && (
              <div className="breakDownRow">
                <div className="breakDownLabel">
                  <span className="warning">Advanced Purchase (10% off)</span>
                </div>
                <div className="breakDownValue">
                  <span className="warning">-${ratePlanDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}
            <div className="breakDownRow">
              <div className="breakDownLabel">
                Municipal Accommodation Tax
              </div>
              <div className="breakDownValue">
                ${MAT.toFixed(2)}
              </div>
            </div> */}
      {/* </div>
          i
        </div>
      </Card> */}
    </AccountContentContainer>
  );
};

export default BookingDetailsContent;
