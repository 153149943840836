// This is a strange code that compensate strange datastructure and help to calculate addons cost based on Addon ID...

export const addonPricingRulesAndNames: Record<string, { name: string; pricingType: "perNight" | "oneTime" | "perItem"; pricingName: "room" | "night" | "meal"; }> = {
    "fd67a4cb-667a-4ba9-bec4-379cb430682a": { name: "Parking", pricingType: "perNight", pricingName: "night" },
    "63f6e8f8-8095-4c79-8d92-ce5812db4f15": { name: "Breakfast", pricingType: "perItem", pricingName: "meal"  },
    "4c773a2c-0d0a-44ba-8680-d34244fea4d5": { name: "Early Check-in", pricingType: "oneTime", pricingName: "room" },
    "c5032166-bd7f-4a8a-bc4b-81d5c7fc5ff5": { name: "Late Check-out", pricingType: "oneTime", pricingName: "room"  },
  };

export const getAddonPricingRule = (addOnId: string) => addonPricingRulesAndNames[addOnId]?.pricingType || "perNight";
export const getAddonPricingName = (addOnId: string) => addonPricingRulesAndNames[addOnId]?.pricingName || "night";

// How to use:
//console.log(getPricingType("4c773a2c-0d0a-44ba-8680-d34244fea4d5")); // "oneTime"
//console.log(getPricingType("63f6e8f8-8095-4c79-8d92-ce5812db4f15")); // "perNight"