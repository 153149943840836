import React from 'react';
import { bookingAddOnsState } from 'states/OptionStates/atoms';
import { useRecoilState } from 'recoil'
import { useQuery } from '@apollo/client';
import { GET_ALL_BOOKING_ADD_ONS } from 'graphql/service';


// Old hook, don't use it
// Current Addons info is inside useLoadTrips

export const useGetBookingAddOns = () => {

  const [bookingAddOns, setBookingAddOns] = useRecoilState(bookingAddOnsState)

  const { loading, error, data: addOnData } = useQuery(
    GET_ALL_BOOKING_ADD_ONS,
    {
      onCompleted: (addOnData) => {
        if (addOnData) {
          setBookingAddOns(addOnData.validBookingAddons)
        }
      },
      onError(error) {
        const content = error.message
        console.log(content)
      }
    }
  )

  return bookingAddOns
}
