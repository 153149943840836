import React, { FC } from 'react';
import { CancelGroupBookingContainer } from './style';
import Card from 'components/Card';
import StyledButton from 'components/Button';
import { colors } from 'constants/colors';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { useHistory, useParams } from 'react-router-dom';
import AccountModal from "components/AccountModal";
import { useState, useEffect   } from 'react';
import CancelBookingModalBody from '../CancelBookingModalBody';
import CancelReasonModalBody from '../CancelReasonModalBody';
import Modal from 'components/Modal';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { cancelBookingModalOpenState, cancelReasonModalOpenState, cancelGroupId, cancelBookingId, canceledGroupIdState, cancelBookingFee, canceledBookingIdState } from 'states/ModalStates/atoms';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import AlertMessage from 'components/AlertMessage';
import { reloadTrips } from  'states/CommonStates/atoms';

const debug = true;


export const CancelGroupBooking: FC = () => {

  const history = useHistory()
  const { trips, loading, refetch } = useLoadTrips()
  const { groupId } = useParams<{ groupId: string }>()
  const trip = trips[groupId]

const [receiveMarketingEmails, setReceiveMarketingEmails] = useState(false);
const [cancelRoomModal, setCancelRoomModal] = useState(false);

const [cancelRoomModalStageOne, setCancelRoomModalStageOne] = useState(true);
const [cancelRoomModalStageTwo, setCancelRoomModalStageTwo] = useState(false);

const [openCancelBookingModal, setOpenCancelBookingModal] = useRecoilState(cancelBookingModalOpenState)
const [openCancelReasonModal, setOpenCancelReasonModal] = useRecoilState(cancelReasonModalOpenState)

const [messageState, setMessageState] = useRecoilState(globalMessageState);
const [errorState, setErrorState] = useRecoilState(globalErrorState);

const [cancelModalGroupId, setCancelModalGroupId] = useRecoilState(cancelGroupId)
const [cancelModalBookingId, setCancelModalBookingId] = useRecoilState(cancelBookingId)
const [cancelModalBookingFee, setCancelModalBookingFee] = useRecoilState(cancelBookingFee)

const [canceledBookingId, setCanceledBookingId] = useRecoilState(canceledBookingIdState);
const [canceledGroupId, setCanceledGroupId] = useRecoilState(canceledGroupIdState);

const closeMessage = () => setMessageState({ isVisible: false, text: '' });
const closeError = () => setErrorState({ isVisible: false, text: '' });


const setReloadTrips = useSetRecoilState(reloadTrips);


useEffect(() => {
  if (canceledBookingId || canceledGroupId) {
    if (debug) {
      console.log("Refetch bookings...")
    }
    setReloadTrips(true);
    //refetch();
  }
  setCanceledBookingId ('')

}, [canceledBookingId]);


useEffect(() => {
  if (canceledGroupId) {
    history.push(`/account/bookings/` + groupId);
    setCanceledGroupId ('');
  }

}, [canceledGroupId]);

useEffect(() => {
  if (debug) {
    if (trips) {
      console.log("Current trips from CancelGroupBooking: ", trips);
    }
  }
}, [trips]);



  if (loading || !trip) {
    return (
      <CancelGroupBookingContainer>
        <div className="accountBody">
          <div className="loading">Loading...</div>
        </div>
      </CancelGroupBookingContainer>
    );
  }

  const cancelBooking = (booking_index: string) => {
    
    setCancelModalBookingId(trips[groupId].bookings[booking_index]?.bookingId);
    setCancelModalGroupId ('');
    const totalPrice = trips[groupId]?.bookings[booking_index]?.roomBookings?.[0]?.pricePerNight!.toString();

    setCancelModalBookingFee(totalPrice);
    setOpenCancelBookingModal(true);

  }


  const cancelGroup = () => {

    setCancelModalGroupId(groupId);
    setCancelModalBookingId ('');

    const totalPrice = trips[groupId]?.bookings
      ?.reduce((sum, booking) => {
        return sum + (booking.roomBookings?.reduce((roomSum, room) => {
          return roomSum + (room.pricePerNight || 0);
        }, 0) || 0);
      }, 0)
      ?.toString();

    setCancelModalBookingFee(totalPrice);
    setOpenCancelBookingModal(true);


}
 


  const bookings = trip.bookings;


  if (debug) {
    console.log(bookings)
  }

  const guestsArray = Object.keys(bookings).map((index) => {

    if (bookings[index].isDeleted || bookings[index].status == 'CANCELLED' ) {return null}
    else {
    return (
      <div className="guestsTableContent" key={index}>
        <div className="col name">{bookings[index].fullName}</div>
        <div className="col">{bookings[index].phoneNumber}</div>
        <div className="col">{bookings[index].roomBookings[0].room.roomType.name}</div>
        <div className="col">
          <span className="remove"
            onClick={() => cancelBooking(index)}
          >
            Remove Room</span>
        </div>
      </div>
    );
    }
  })


  return (
    <CancelGroupBookingContainer>

            <AlertMessage
                showMessage={messageState.isVisible}
                //messageType = 'error'
                onClose={() => closeMessage()} // Changing state on close
            >
                {messageState.text}
            </AlertMessage>
            
            <AlertMessage
                showMessage={errorState.isVisible}
                messageType = 'error'
                onClose={() => closeError()} // Changing state on close
            >
                {errorState.text}
            </AlertMessage>

      {/* Cancel room Modal
      <AccountModal
        open={cancelRoomModal}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Remove room from booking"
        // onClose={() => setCancelRoom(false)}
      >
        <div className="accounModalBody emailModal">
          <h4 className="confirmationModalHeader">Are you sure you want to remove this room from your booking?</h4>
          <div className="confirmationModalText">
            This booking was made with a flex rate plan. If you cancel before [datetime which is 24 hours before check in] you will not incur any charges. If you cancel after this date there will be a fee of $[calculation of one room night x number of rooms].
          </div>
          <div className="feedback">
            <div className="feedbackText">

            </div>


          </div>
          <div className="controls">
          <div className="remove">Remove Room</div>
          <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // onClick={() => {
              //   setCancelRoom(false);
              //   setCancelRoomFeedback(true);
              // }}
            // disabled={oldPassword.length < 8 || newPassword.length < 8}
            // onClick={handleResetPassword}
            >
              Keep Room
            </StyledButton>
          </div>

        </div>
      </AccountModal> */}



      <div className="cardContent">
        <h2 className="guestsHeader">Guest Information</h2>
        <div className="guestsTableHeader">
          <div className="col">GUEST NAME</div>
          <div className="col">GUEST PHONE </div>
          <div className="col">ROOM TYPE</div>
          <div className="col">ACTION</div>
        </div>
        <div className="guestsTableBody">
        {guestsArray}
        </div>
        <div className="removeBooking">
        <StyledButton
            width="169px"
            height="48px"
            //backgroundColor = {colors.signInBorderColor}
            //fontColor = {colors.white}
            disabledBackgroundColor={colors.signInBorderColor}
            disabledColor={colors.white}
          // disabled={oldPassword.length < 8 || newPassword.length < 8}
          onClick={() => history.push(`/account/bookings`)}
          >
            Back to Bookings
          </StyledButton>

          <StyledButton
            width="199px"
            height="48px"
            //backgroundColor = {colors.signInBorderColor}
            //fontColor = {colors.white}
            disabledBackgroundColor={colors.signInBorderColor}
            disabledColor={colors.white}
          // disabled={oldPassword.length < 8 || newPassword.length < 8}
           onClick={() => cancelGroup() }
          >
            Cancel Entire Booking
          </StyledButton>
        </div>

        <Modal open={openCancelBookingModal} >
          <CancelBookingModalBody 
          />
        </Modal>
        <Modal open={openCancelReasonModal} >
          <CancelReasonModalBody 
          />
        </Modal>

      </div>

    </CancelGroupBookingContainer>
  );
};

export default CancelGroupBooking;
