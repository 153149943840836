import React from 'react';
import Card from 'components/Card';
import { AddOnCardContainer } from './style';
import { SwitchOffIconNew, SwitchOnIconNew, GreenCheckIconNew, ContPlus, ContMinus } from 'assets/icons';

export type AddOnCardProps = {
  title: string;
  attributes: string[];
  checked?: boolean;
  setChecked?: () => void;
  value?: number;
  setValue?: (newValue: number) => void;
  price: number;
  isOneTimePriced: boolean;
};



const AddOnCard: React.FC<AddOnCardProps> = ({
  title,
  attributes,
  checked,
  setChecked,
  value,
  setValue,
  price,
  isOneTimePriced
}) => {

  const handleIncrement = (e: React.MouseEvent) => {
    e.stopPropagation(); 
    if (setValue && typeof value === "number") {
      setValue(value + 1);
    }
  };
  
  const handleDecrement = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (setValue && typeof value === "number" && value > 0) {
      setValue(value - 1);
    }
  };

  return (
    <AddOnCardContainer
      // onClick={setChecked}
    >
      <div className="optionCardTitle">
        {title}
      </div>
      <div className="optionCardAttributes">
        {
          attributes.map((attribute, index) => {
            return (
              <div key={index} className="roomAttribute">
                <GreenCheckIconNew />
                {attribute}
              </div>
            )
          })
        }
      </div>
      <div className="optionCardPrice">
      {typeof value === "number" && setValue ? (
    <div className="counter">
      <ContMinus className="counter-btn" onClick={handleDecrement} />
      <span className="counter-value">{value}</span>
      <ContPlus onClick={handleIncrement} className="counter-btn" />
    </div>
  ) : (
    <div className={`switch ${checked ? "active" : ""}`} onClick={setChecked}>
      <div className={`switchSlider ${checked ? "active" : ""}`}>
        {checked ? <SwitchOnIconNew /> : <SwitchOffIconNew />}
      </div>
    </div>
  )}
        <div className="price">
          {`+ $${price.toFixed(2)}`}
        </div>
        <div className="explanation">
          {`per room, ${isOneTimePriced ? '' : 'per night, '} not including tax / fees`}
        </div>
      </div>
    </AddOnCardContainer>
  )
}
export default AddOnCard;
