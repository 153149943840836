// dateString format 2025-03-09T08:00:00.373Z
// check if date is today
export const isToday = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    
    return date.getUTCFullYear() === today.getUTCFullYear() &&
           date.getUTCMonth() === today.getUTCMonth() &&
           date.getUTCDate() === today.getUTCDate();
  };
  
